<!-- TODO: No añadir botón de añadir categoría -->
<!-- Añadir popup de confirmación de eliminación -->
<template>     
    <div>
        <v-toolbar flat prominent :height="$vuetify.breakpoint.mdAndDown ? 40 : 80" class="px-lg-4" :extension-height="$vuetify.breakpoint.mdAndDown ? 220 : 60" style="z-index: 1;">
            <h2 class="mt-3">
                Ausencia laboral
            </h2>
            <template v-slot:extension>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-text-field v-model="filters.name" outlined dense solo flat label="Buscar por nombre" prepend-icon="mdi-magnify" clearable :hide-details="$vuetify.breakpoint.mdAndDown">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete v-model="filters.timeFrame" :items="timeFrames" item-text="text" item-value="value" return-object outlined dense solo flat label="Marco temporal" prepend-icon="mdi-calendar-arrow-right" :hide-details="$vuetify.breakpoint.mdAndDown">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-autocomplete v-model="filters.employees" :items="employees" item-text="fullName" item-value="id" label="Equipo" solo flat outlined dense multiple prepend-icon="mdi-account-multiple" :hide-details="$vuetify.breakpoint.mdAndDown">
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="!allEmployees" :color="`${item.color?.split(' ')[0]} lighten-3`" small label class="rounded-lg mr-1">
                                    {{ item.fullName }}
                                </v-chip>
                                <span v-else-if="index==0" class="body-1 pl-2 pt-1 grey--text text--darken-1 font-weight-bold">
                                    Todo el equipo
                                </span>
                            </template>
                            <template v-slot:prepend-item>
                                <v-list-item @click="allEmployees=!allEmployees">
                                    <v-list-item-action>
                                        <v-checkbox v-model="allEmployees" readonly color="primary"></v-checkbox> 
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Todo el equipo</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-btn
                fab
                color="primary"
                class="mt-n5"
                bottom
                right
                absolute
                @click="$router.push('vacations/new')"
                >
                <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card v-for="(vacation, idx) in filteredVacations" :key="`vacation-${vacation.id}`" outlined class="rounded-0" :class="idx==0 ? 'rounded-t-lg' : idx==vacations.length-1 ? 'rounded-b-lg' : ''" :style="idx>0 ? 'border-top: 0;' : ''">
                <v-card-text class="px-5 black--text d-flex flex-row align-center">
                    <div class="mr-1 mr-lg-3" style="min-height: 110px; min-width: 80px">
                        <MicroCalendarComponent :value="vacation.startDate" :width="$vuetify.breakpoint.mdAndDown ? '48px' : '60px'" :from="vacation.startDate" :to="vacation.endDate"/>
                    </div>
                    <v-row>
                        <v-col cols="12" lg="6" class="d-flex flex-column justify-center">
                            <span class="body-1 font-weight-bold grey--text text--darken-3">
                                {{ dateToLocale(vacation.startDate, vacation.isFullDay) }} - {{ dateToLocale(vacation.endDate, vacation.isFullDay) }}
                            </span>
                            <div class="mt-1">
                                <span>{{ vacation.name }}</span>
                            </div>
                        </v-col>
                        <v-col cols="12" lg="6" class="d-flex align-center">
                            <div class="d-block align-center">
                                <template v-if="vacation.employees.length<10">
                                    <v-chip  v-for="(employee, employeeIdx) in vacation.employees" :key="`employee-${vacation.id}-${employeeIdx}`" small outlined :color="`${employee.color?.split(' ')[0]} lighten-2`" label class="mr-1 mb-1 py-4 rounded-lg">
                                        <v-avatar :color="`${employee.color?.split(' ')[0]} lighten-2`" class="grey--text text--lighten-4 rounded-lg ml-n2 mr-2" tile >{{ employee.initials }}</v-avatar>
                                        {{ employee.fullName }}
                                    </v-chip>
                                </template>
                                <template v-else>
                                    <v-avatar v-for="(employee, employeeIdx) in vacation.employees" :key="`employee-${vacation.id}-${employeeIdx}`" :color="`${employee.color?.split(' ')[0]} lighten-2`" class="grey--text text--lighten-4 rounded-lg mr-1 mb-1" tile size="33">{{ employee.initials }}</v-avatar>
                                </template>
                            </div>
                        </v-col>
                    </v-row>
                    <div class="d-flex self-align-end align-center">
                        <v-menu class="pa-3 rounded-lg">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn color="grey darken-1" class="font-weight-black mr-3 rounded-lg" icon v-bind="attrs"
                                    v-on="on">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item link @click="$router.push(`vacations/${vacation.id}`)">
                                    <v-list-item-title class="body-2" @click="imageDialog = true">
                                        <v-icon left>mdi-pencil</v-icon>
                                        Editar
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item link @click="remove(vacation.id)">
                                    <v-list-item-title class="body-2">
                                        <v-icon left color="red">mdi-delete-outline</v-icon>
                                        <span class="red--text">Eliminar</span>
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>                  
                </v-card-text>
            </v-card>
            <v-card v-show="filteredVacations.length==0" outlined class="rounded-lg">
                <v-card-text>
                    <v-icon left>mdi-magnify-remove-outline</v-icon>
                    No se han encontrado resultados
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import MicroCalendarComponent from '@/components/business/MicroCalendarComponent.vue'

export default {
    name: 'VacationsView',
    components: {
        MicroCalendarComponent
    },
    data() {
        return {
            filters: {
                name: '',
                timeFrame: null,
                employees: []
            },
        }
    },
    computed: {
        ...mapGetters(['vacations', 'employees']),
        filteredVacations() {
            let vacations = this.vacations.filter(vacation => vacation.name.toLowerCase().includes(this.filters.name.toLowerCase()))
            // TODO: Filtrar por fechas y empleados
            if (this.filters.timeFrame) {
                vacations = vacations.filter(vacation => {
                    let start = moment(vacation.startDate);
                    let end = moment(vacation.endDate);
                    let timeFrame = this.filters.timeFrame.value
                    let timeFrameStart = moment(timeFrame.from)
                    let timeFrameEnd = moment(timeFrame.to).endOf('day')
                    return start.isBetween(timeFrameStart, timeFrameEnd, null, '[]') || end.isBetween(timeFrameStart, timeFrameEnd, null, '[]')
                })
            }
            if (this.filters.employees.length > 0) {
                vacations = vacations.filter(vacation => {
                    let employees = vacation.employees.map(employee => employee.id)
                    return this.filters.employees.filter(employee => employees.includes(employee)).length > 0
                })
            }
            return vacations
        },
        allEmployees: {
            get() {
                return this.filters.employees.length === this.employees.length
            },
            set(value) {
                if (value) {
                    this.filters.employees = this.employees.map(employee => employee.id)
                } else {
                    this.filters.employees = []
                }
            }
        },
        timeFrames() {
            return [
                {
                    text: 'Todo el tiempo',
                    value: {
                        from: moment().subtract(100, 'years').format('YYYY-MM-DD'),
                        to: moment().add(100, 'years').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Semana pasada',
                    value: {
                        from: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Esta semana',
                    value: {
                        from: moment().startOf('week').format('YYYY-MM-DD'),
                        to: moment().endOf('week').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Semana próxima',
                    value: {
                        from: moment().add(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
                        to: moment().add(1, 'weeks').endOf('week').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Mes anterior',
                    value: {
                        from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Este mes',
                    value: {
                        from: moment().startOf('month').format('YYYY-MM-DD'),
                        to: moment().endOf('month').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Mes próximo',
                    value: {
                        from: moment().add(1, 'months').startOf('month').format('YYYY-MM-DD'),
                        to: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Año anterior',
                    value: {
                        from: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD'),
                        to: moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Este año',
                    value: {
                        from: moment().startOf('year').format('YYYY-MM-DD'),
                        to: moment().endOf('year').format('YYYY-MM-DD')
                    }
                },
                {
                    text: 'Año próximo',
                    value: {
                        from: moment().add(1, 'years').startOf('year').format('YYYY-MM-DD'),
                        to: moment().add(1, 'years').endOf('year').format('YYYY-MM-DD')
                    }
                }
            ]
        }
    },
    async created() {
            await this.fetchVacations()
            // this.vacations.forEach(vacation => {
            //     let start = moment(vacation.start);
            //     let end = moment(vacation.end);

            //     // Formatea la fecha y la hora para que se ajuste al formato de los inputs
            //     vacation.startDate = start.format('YYYY-MM-DD');
            //     vacation.startTime = start.format('HH:mm');
            //     vacation.endDate = end.format('YYYY-MM-DD');
            //     vacation.endTime = end.format('HH:mm');
            // });
        if (this.employees.length === 0) await this.fetchEmployees()
    },
    mounted() {
        
    },
    methods: {
        ...mapActions(['fetchVacations', 'fetchEmployees', 'deleteVacation']),
        dateToLocale(date, isFullDay=false) {
            const momentDate = moment(date)
            return isFullDay ? momentDate.locale('es').format('LL') : momentDate.locale('es').format('LLL')
        },
        async remove(id) {
            await this.deleteVacation(id)
            await this.fetchVacations()
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
