<template>
    <v-navigation-drawer app class="translucient-white pt-2" left clipped width="300" v-model="drawerValue" v-if="$vuetify.breakpoint.mdAndDown">
        <v-list nav :value="selectedItem">
            <v-list-group
                v-for="(item, itemIdx) in navigationItems"
                :key="`item-${itemIdx}`"
                :prepend-icon="item.icon"
                :append-icon="item.items.length>0 && 'mdi-chevron-down'"
                no-action
                :color="item.items.length>0 ? 'grey darken-3': 'teal accent-4'"
                @click.stop="linkTo(item.path)"
                :value="selectedItem == itemIdx"
                :disabled="selectedItem == itemIdx"
            >
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title v-text="item.title" class="font-weight-bold body-2"></v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item
                v-for="(child, childIdx) in item.items"
                :key="`child-${itemIdx}-${childIdx}`"
                @click.stop="linkTo(child.path)"
                :input-value="`${selectedItem}-${selectedSubitem}` == `${itemIdx}-${childIdx}`"
                color="teal accent-4"
                >
                    <v-list-item-content>
                        <v-list-item-title class="font-weight-bold body-2">{{ child.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
        <v-divider class="mt-3"></v-divider>
    </v-navigation-drawer>
</template>

<script>

export default {
    name: 'MobileNavigationDrawer',
    props: {
        value: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
            // selectedItem: 0,
        }
    },
    computed: {
        drawerValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        selectedItem() {
            for (let i = 0; i < this.navigationItems.length; i++) {
                const item = this.navigationItems[i]
                if (this.$route.path.includes(item.path)) {
                    return i
                }
                for (let j = 0; j < item.items.length; j++) {
                    const child = item.items[j]
                    if (this.$route.path.includes(child.path)) {
                        return i
                    }
                }
            }
            return 0
        },
        selectedSubitem() {
            const item = this.navigationItems[this.selectedItem]
            if (item) {
                return item.items.findIndex(child =>  this.$route.path.includes(child.path))
            }
            return 0
        },
        navigationItems() {
            // activity: {
            //         // name: 'activity',
            //         text: 'Actividad',
            //         icon: 'mdi-finance',
            //         path: `/businesses/${businessId}/activity`,
            //     },
            //     calendar: {
            //         // name: 'calendar',
            //         text: 'Calendario',
            //         icon: 'mdi-calendar',
            //         path: `/businesses/${businessId}/calendar`,
            //     },
            //     clients: {
            //         // name: 'clients',
            //         text: 'Clientes',
            //         icon: 'mdi-account-group',
            //         path: `/businesses/${businessId}/clients`,
            //     },
            //     business: {
            //         // name: 'business',
            //         text: 'Empresa',
            //         icon: 'mdi-store-cog',
            //         path: `/businesses/${businessId}/business`,
            //     },
            //     marketing: {
            //         // name: 'marketing',
            //         text: 'Marketing',
            //         icon: 'mdi-star-shooting-outline',
            //         path: `/businesses/${businessId}/marketing`,
            //     },
            const businessId = localStorage.getItem('businessId')
            return [
                {
                    icon: 'mdi-store',
                    title: 'Actividad',
                    path: `/businesses/${businessId}/activity`,
                    items: [],
                },
                {
                    icon: 'mdi-calendar',
                    title: 'Calendario',
                    path: `/businesses/${businessId}/calendar`,
                    items: [],
                },
                {
                    icon: 'mdi-account-group',
                    title: 'Clientes',
                    path: `/businesses/${businessId}/clients`,
                    items: [],
                },
                {
                    icon: 'mdi-store',
                    title: 'Mi empresa',
                    path: `/businesses/${businessId}/business/details`,
                    items: [
                        { 
                            title: 'Perfil de la empresa',
                            path: `/businesses/${businessId}/business/details`,
                        },
                        { 
                            title: 'Facturación',
                            path: `/businesses/${businessId}/business/billing`,
                        },
                    ],
                },
                {
                    icon: 'mdi-calendar-clock',
                    title: 'Planificación',
                    path: `/businesses/${businessId}/business/services`,
                    items: [
                        { 
                            title: 'Servicios', 
                            path: `/businesses/${businessId}/business/services`,
                        },
                        { 
                            title: 'Ajustes de reservas',
                            path: `/businesses/${businessId}/business/rules`,
                        },
                        { 
                            title: 'Equipo',
                            path: `/businesses/${businessId}/business/employees`,
                        },
                        { 
                            title: 'Ausencia laboral',
                            path: `/businesses/${businessId}/business/vacations`,
                        },
                        { 
                            title: 'Notificaciones',
                            path: `/businesses/${businessId}/business/notifications`,
                        },
                    ],
                },
                { 
                    icon: 'mdi-star-shooting-outline',
                    title: 'Marketing',
                    path: `/businesses/${businessId}/marketing`,
                    items: [],
                },
            ]
        }
    },
    methods: {
        linkTo(path) {
            this.$router.push(path)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
