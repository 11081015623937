<!-- TODO: No añadir botón de añadir categoría -->
<template>     
    <div>
        <v-toolbar flat :height="$vuetify.breakpoint.mdAndDown ? 180 : 100" class="px-lg-4">
            <div class="d-flex flex-column">
                <h2>Notificaciones</h2>
                <span class="font-weight-regular grey--text text--darken-2">
                    Mantén tus reservas en la mente de tus clientes enviando notificaciones de recordatorio y seguimientos. ¡Es como tener un sistema de recordatorios automático en su bolsillo!
                </span>
            </div>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card v-for="(notificationType, notificationTypesIdx) in notificationTypes" :key="`notification-${notificationTypesIdx}`" outlined class="rounded-0" :class="notificationTypesIdx==0 ? 'rounded-t-lg' : notificationTypesIdx==notificationTypes.length-1 ? 'rounded-b-lg' : ''" :style="notificationTypesIdx>0 ? 'border-top: 0;' : ''">
                <v-card-text class="pa-6 black--text d-flex flex-row">
                    <v-row>
                        <v-col cols="12" lg="10" class="d-flex flex-column justify-center">
                            <span class="body-1 font-weight-bold primary--text">
                                <v-icon left color="primary">{{ notificationType.icon }}</v-icon>
                                {{ notificationType.name }}
                                <span class="ml-2 body-2 font-weight-regular black--text">({{ notificationResult(notificationType) }})</span>
                            </span>
                            <span class="body-1 mt-1">
                                <!-- <v-icon left>mdi-text</v-icon> -->
                                <span class="ml-9"></span>
                                {{ notificationType.description }}
                            </span>
                        </v-col>
                        <v-col cols="12" lg="2" class="d-flex justify-end align-center">
                            <v-chip :color="notificationConfig[`${notificationType.value}Email`] ? 'primary' : 'grey'" label class="px-5 rounded-lg" :outlined="!notificationConfig[`${notificationType.value}Email`]" @click="notificationConfig[`${notificationType.value}Email`]=!notificationConfig[`${notificationType.value}Email`]">
                            <!-- <v-chip :color="notificationConfig[`${notificationType.value}Email`] ? 'primary' : 'grey'" label class="px-5 rounded-lg" :outlined="!notificationConfig[`${notificationType.value}Email`]"> -->
                                <v-icon small left>
                                    mdi-email
                                </v-icon>
                                Email
                            </v-chip>
                            <v-chip :color="notificationConfig[`${notificationType.value}Sms`] ? 'primary' : 'grey'" label class="px-5 rounded-lg ml-2" :outlined="!notificationConfig[`${notificationType.value}Sms`]" @click="notificationConfig[`${notificationType.value}Sms`]=!notificationConfig[`${notificationType.value}Sms`]">
                            <!-- <v-chip :color="notificationConfig[`${notificationType.value}Sms`] ? 'primary' : 'grey'" label class="px-5 rounded-lg ml-2" :outlined="!notificationConfig[`${notificationType.value}Sms`]"> -->
                                <v-icon small left>
                                    mdi-message
                                </v-icon>
                                SMS
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <div class="d-flex flex-row justify-end align-center pt-1 pb-5 py-lg-1">
                <v-btn text :disabled="!hasChanged">Descartar</v-btn>
                <v-btn
                color="primary"
                class="py-5 px-6 rounded-lg ml-2"
                small
                :disabled="!hasChanged"
                @click="save"
                >
                <v-icon left>mdi-content-save</v-icon>
                Guardar
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'NotificationsView',
    components: {
    },
    data() {
        return {
            filter: '',
            notificationTypes: [
                {
                    name: 'Reserva pendiente',
                    description: 'Envía una notificación de cita pendiente a los clientes que tienen una cita pendiente de confirmar por ti.',
                    value: 'pending',
                    icon: 'mdi-calendar-clock',
                },
                { 
                    name: 'Reserva confirmada', 
                    description: 'Envía un notificación de cita confirmada a los clientes que han confirmado su cita.',
                    value: 'confirmed',
                    icon: 'mdi-check',
                },
                { 
                    name: 'Reserva cancelada',
                    description: 'Envía un notificación de cita cancelada a los clientes que han cancelado su cita.',
                    value: 'cancelled',
                    icon: 'mdi-account-off',
                },
                { 
                    name: 'Reserva rechazada',
                    description: 'Envía un notificación de cita rechazada a los clientes cuya cita ha sido rechazada.',
                    value: 'rejected',
                    icon: 'mdi-account-remove',
                },
                { 
                    name: 'Reserva reprogramada', 
                    description: 'Envía un notificación de cita reprogramada a los clientes cuya cita ha sido reprogramada.',
                    value: 'rescheduled', 
                    icon: 'mdi-calendar-sync',
                },
                { 
                    name: 'Recordatorio de cita',
                    description: 'Envía un recordatorio de cita a los clientes que tienen una cita en las próximas horas (ver Ajuste de reservas).', 
                    value: 'reminder', 
                    icon: 'mdi-calendar-start',
                },
                { 
                    name: 'Recordatorio de reseña',
                    description: 'Envía un recordatorio de reseña de Google a los clientes que han tenido una cita recientemente.',
                    value: 'review', 
                    icon: 'mdi-star',
                },
            ],
            notificationConfig: {},
            defaultNotificationConfig: "",
            hasChanged: false,
        }
    },
    // TODO: Obtener notificaciones de la base de datos
    computed: {
        serializedNotificationConfig() {
            return JSON.stringify(this.notificationConfig)
        },
    },
    watch: {
        notificationConfig: {
            handler: function() {
                this.hasChanged = this.serializedNotificationConfig !== this.defaultNotificationConfig && this.defaultNotificationConfig !== ''
            },
            deep: true
        }
    },
    async created() {
        if (Object.keys(this.notificationConfig).length === 0) {
            this.notificationConfig = await this.fetchNotificationConfig();
            this.defaultNotificationConfig = JSON.stringify(this.notificationConfig);
        }
        console.log(this.notificationConfig);
    },
    mounted() {
        
    },
    methods: {
        ...mapActions(['fetchNotificationConfig', 'updateNotificationConfig']),
        notificationResult(notificationType) {
            const isSms = this.notificationConfig[`${notificationType.value}Sms`];
            const isEmail = this.notificationConfig[`${notificationType.value}Email`];
            if (isSms && isEmail) return 'SMS y Email';
            if (isSms) return 'SMS';
            if (isEmail) return 'Email';
            return 'Sin notificar';
        },
        async save() {
            await this.updateNotificationConfig(this.notificationConfig)
            this.hasChanged = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
