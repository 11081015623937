import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        activity: {},
    },
    getters: {
        activity: state => state.activity,
    },
    mutations: {
        setActivity(state, activity) {
            state.activity = activity
        },
    },
    actions: {
        async fetchActivity({ commit }, { startDate, endDate }) {
            const response = await api.get(`activity/${startDate}/${endDate}`, { params: {} })
            if (response.status === 200) {
                const activity = response.data.activity
                commit('setActivity', activity)
                return activity
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener los ajustes de notificaciones', color: 'error' })
            }
        },
    },
};
