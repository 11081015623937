<template>     
    <div>
        <v-toolbar flat height="80" class="px-4">
            <h2>
                Políticas
            </h2>
        </v-toolbar>
        <v-container fluid class="pa-8">
            <v-card outlined class="rounded-lg mb-4">
                <v-card-text class="px-5 black--text">
                    <v-row>
                        <v-col cols="10" class="d-flex flex-column">
                            <span class="text-h6">Términos y condiciones</span>
                            <span>Si cuentas con términos y condiciones para tus clientes, puedes agregarlos en esta sección. Durante el proceso de reserva, se les solicitará a tus clientes que los acepten.</span>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end align-center">
                            <v-btn color="teal accent-4" class="font-weight-black" text @click="termsDialog=true">Añadir</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg">
                <v-card-text class="px-5 black--text">
                    <v-row>
                        <v-col cols="10" class="d-flex flex-column">
                            <span class="text-h6">Política de privacidad</span>
                            <span>¿Necesitas que los clientes acepten tu política de privacidad? Simplemente añádelo aquí.</span>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end align-center">
                            <v-btn color="teal accent-4" class="font-weight-black" text @click="policyDialog=true">Añadir</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <!-- TODO: Mensajes promocionales and Retención de datos -->
        </v-container>
        <v-dialog
        fullscreen
        v-model="termsDialog"
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar flat color="px-3">
                    <v-btn icon @click="termsDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Términos y condiciones</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="teal accent-4" class="py-3 px-5 font-weight-black elevation-0 rounded-lg" dark @click="termsDialog = false">
                        Guardar
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-10 grey lighten-3 d-flex justify-center">
                    <v-sheet width="880" height="1040" class="pa-15 white">
                        <h1 class="pa-3">Términos y condiciones</h1>
                        <v-textarea
                            label="Cuéntanos sobre tus términos y condiciones"
                            auto-grow
                            class="transparent"
                            solo
                            flat
                            v-model="terms"
                        ></v-textarea>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
        fullscreen
        v-model="policyDialog"
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar flat color="px-3">
                    <v-btn icon @click="policyDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-toolbar-title>Política de privacidad</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="teal accent-4" class="py-3 px-5 font-weight-black elevation-0 rounded-lg" dark @click="policyDialog = false">
                        Guardar
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-10 grey lighten-3 d-flex justify-center">
                    <v-sheet width="880" height="1040" class="pa-15 white">
                        <h1 class="pa-3">Política de privacidad</h1>
                        <v-textarea
                            label="Cuéntanos sobre tu política de privacidad"
                            auto-grow
                            class="transparent"
                            solo
                            flat
                            v-model="terms"
                        ></v-textarea>
                    </v-sheet>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: 'PoliciesView',
    components: {
    },
    data() {
        return {
            termsDialog: false,
            terms: "",
            policyDialog: false,
            policy: "",
        }
    },
    computed: {
        
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
