import { render, staticRenderFns } from "./ActivityView.vue?vue&type=template&id=2f77ad26&scoped=true"
import script from "./ActivityView.vue?vue&type=script&lang=js"
export * from "./ActivityView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f77ad26",
  null
  
)

export default component.exports