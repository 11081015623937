<template>
    <v-app-bar
        color="translucient-white"
        class="elevation-0" 
        app
        clipped-left
        outlined
    >
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')" v-if="$vuetify.breakpoint.mdAndDown"></v-app-bar-nav-icon>
        <v-spacer v-if="$vuetify.breakpoint.mdAndDown"></v-spacer>
        <v-img 
            :src="require('@/assets/logo-teal.png')"
            max-height="30"
            max-width="200"
            contain
            @click="$router.push('/')"
            style="cursor: pointer;"
        ></v-img>
        <v-spacer></v-spacer>
        <v-btn v-for="section in sections" :key="section.title" text @click="$router.push(section.path)" class="text-none font-weight-bold d-none d-lg-block body-2" :class="$route.path.includes(section.path) ? 'teal--text text--accent-4' : 'grey--text text--darken-2'">
            <v-icon class="mr-2" :color="$route.path.includes(section.path) ? 'teal accent-4' : 'grey darken-1'">{{ section.icon }}</v-icon>{{ section.text }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="teal accent-4" class="mr-3 pa-3 px-5 font-weight-black d-none d-md-flex elevation-0" @click="$router.push('/login')" dark disabled>Actualizar</v-btn>
        <!-- <v-avatar size="37" color="blue-grey lighten-5 rounded-lg" class="mr-lg-5" tile>JS</v-avatar> -->
    </v-app-bar>
</template>

<script>
export default {
    name: 'AppBar',
    data () {
        return {
            selectedSection: 'activity',
        }
    },
    computed: {
        sections () {
            const businessId = localStorage.getItem('businessId')
            return {
                activity: {
                    // name: 'activity',
                    text: 'Actividad',
                    icon: 'mdi-finance',
                    path: `/businesses/${businessId}/activity`,
                },
                calendar: {
                    // name: 'calendar',
                    text: 'Calendario',
                    icon: 'mdi-calendar',
                    path: `/businesses/${businessId}/calendar`,
                },
                clients: {
                    // name: 'clients',
                    text: 'Clientes',
                    icon: 'mdi-account-group',
                    path: `/businesses/${businessId}/clients`,
                },
                business: {
                    // name: 'business',
                    text: 'Empresa',
                    icon: 'mdi-store-cog',
                    path: `/businesses/${businessId}/business`,
                },
                marketing: {
                    // name: 'marketing',
                    text: 'Marketing',
                    icon: 'mdi-star-shooting-outline',
                    path: `/businesses/${businessId}/marketing`,
                },
            }
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    a {
        text-decoration: none;
    }
</style>
