<template>
    <v-dialog :value="dialog" fullscreen hide-overlay transition="scroll-x-reverse-transition">
        <v-card>
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="10" md="6">
                    <v-card class="elevation-0">
                        <v-toolbar class="elevation-0">
                            <v-btn icon @click="back()" large>
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="ml-4 my-6 font-weight-black text-break secondary--text"
                            style="font-size: 35px; line-height: 45px;">Preguntas frecuentes</v-card-title>
                        <v-divider class="mx-4"></v-divider>
                        <v-list>
                            <v-list-group color="primary" class="mx-2" v-model="item.active"
                                v-for="item in frequent_questions.business" :key="item.id">
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <span>{{ item.question }}</span>
                                    </v-list-item-content>
                                </template>
                                <v-list-item class="py-3">
                                    <v-list-item-content>
                                        <span class="font-weight-regular">{{ item.answer }}</span>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-group>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'FrequentQuestionsComponent',
    props: ['dialog'],
    data() {
        return {
            frequent_questions: {
                business: [
                    {
                        id: 1,
                        question: '¿Puedo cobrar los servicios a través de slotspot.es?',
                        answer: 'No, actualmente no ofrecemos servicios de pago. Sin embargo, seguimos trabajando y ofreceremos esta opción en el futuro.'
                    },
                    {
                        id: 2,
                        question: '¿Puedo configurar pausas entre cada servicio?',
                        answer: 'No es posible configurar pausas, te recomendamos que extiendas la duración del servicio. Por ejemplo, si deseas que el servicio dure 1 hora y quieres tener 15 minutos de descanso entre cada servicio, configura la duración del servicio en 1 hora y 15 minutos.'
                    },
                    {
                        id: 3,
                        question: '¿Qué hago si un cliente no se presenta a su reserva?',
                        answer: 'Si un cliente no se presenta a su reserva y no ha cancelado, puedes marcar la reserva como "No se presentó" en listado de clientes. Esta informacción estará visible en el perfil del cliente y tendrás la opción de bloquear a los clientes manualmente.'    
                    },
                    // {
                    //     id: 4,
                    //     question: '¿Cómo puedo cambiar mi horario de apertura?',
                    //     answer: 'Para cambiar tu horario de apertura, debes ir a la sección "Mi perfil" y seleccionar la opción "Configuración de horario". Allí puedes establecer tu horario de apertura y cierre.'
                    // },
                    // {
                    //     id: 6,
                    //     question: '¿Cómo puedo cancelar una reserva?',
                    //     answer: 'Para cancelar una reserva, ve al listado de tus reservas y selecciona la reserva que quieres cancelar. En la página de detalles de la reserva, encontrarás la opción de cancelar la reserva.'
                    // },
                    // {
                    //     id: 7,
                    //     question: '¿Cómo puedo bloquear a un cliente?',
                    //     answer: 'Puedes bloquear a un cliente yendo a su perfil y seleccionando la opción "Bloquear cliente". Esto impedirá que el cliente haga reservas contigo en el futuro.'
                    // },
                    {
                        id: 8,
                        question: "¿Cómo puedo ofrecer descuentos en mis servicios?",
                        answer: "Actualmente, slotspot.es no admite la opción de ofrecer descuentos directamente a través de la plataforma. Sin embargo, puedes ajustar los precios de tus servicios en cualquier momento a través de la configuración de tu perfil."
                    },
                    {
                        id: 16,
                        question: "¿Puedo usar slotspot.es en varios dispositivos?",
                        answer: "Sí, puedes acceder a tu cuenta de slotspot.es en cualquier dispositivo con acceso a internet. Solo necesitas tu nombre de usuario y contraseña para iniciar sesión."
                    },
                ],
            }
        }
    },
    methods: {
        back() {
            this.$emit('update:dialog', false);
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
