import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        employees: [],
    },
    getters: {
        employees: state => state.employees,
    },
    mutations: {
        setEmployees(state, employees) {
            state.employees = employees
        },
    },
    actions: {
        async fetchEmployees({ commit }) {
            const response = await api.get(`employees`, { params: {} })
            if (response.status === 200) {
                commit('setEmployees', response.data.employees)
            }
        },
        async fetchEmployee({ commit }, id) {
            const response = await api.get(`employees/${id}`, { params: {} })
            if (response.status === 200) {
                return response.data.employee
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener el empleado', color: 'error' })
            }
        },
        async createEmployee({ commit }, employee) {
            const response = await api.post(`employees`, employee)
            if (response.status === 201) {
                commit('setSnackbar', { show: true, text: 'Empleado creado correctamente', color: 'success' })
                return response.data.employee
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al crear el empleado', color: 'error' })
            }
        },
        async updateEmployee({ commit }, employee) {
            const response = await api.put(`employees/${employee.id}`, employee)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Empleado actualizado correctamente', color: 'success' })
                return response.data.employee
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar el empleado', color: 'error' })
            }
        },
        async deleteEmployee({ commit }, id) {
            const response = await api.delete(`employees/${id}`, { params: {} })
            if (response.status === 204) {
                commit('setSnackbar', { show: true, text: 'Empleado eliminado correctamente', color: 'success' })
                return true
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al eliminar el empleado', color: 'error' })
                return false
            }
        },
    },
};
