import axios from 'axios'
import store from '@/plugins/store'
import router from '@/plugins/router'

const api = axios.create()
const businessId = localStorage.getItem('businessId')

api.defaults.baseURL = `${process.env.VUE_APP_API_HOST}:${process.env.VUE_APP_API_PORT}/api/v1/businesses/${businessId}/`
api.defaults.headers.common["Content-Type"] = "application/json"
api.defaults.headers.common["Accept"] = "application/json"

api.interceptors.request.use(function (config) {
    if (config.method !== 'get') {
        store.commit('startLoading')
    }
    const token = store.state.auth.token || localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function (error) {
    store.commit('stopLoading')
    return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
    store.commit('stopLoading')
    return response;
}, function (error) {
    store.commit('stopLoading')
    if (error.response.status === 403) {
        store.dispatch('auth/logout')
        router.push('/login')
    }
    return Promise.reject(error)
})

export default api
