<template>
    <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="scroll-x-reverse-transition"
    
    >
        <v-card>
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="10" md="6">
                    <v-card class="elevation-0 pb-16">
                        <v-toolbar
                            class="elevation-0"
                            >
                            <v-btn
                                icon
                                @click="back()"
                                large
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="ml-4 my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Contacto</v-card-title>
                        <v-divider class="mx-4"></v-divider>

                        <v-card class="elevation-0 mx-4" v-for="item in contact_info" :key="'tac_'+item.id">
                            <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3><v-icon left color="secondary">{{ item.icon }}</v-icon>{{ item.title }}</h3></v-card-title>
                            <v-divider v-if="item.title" class="mx-4"></v-divider>
                            <v-card-text class="text-break" v-for="text in item.content" :key="text" style="font-size: 18px;" v-html="text">
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'ContactComponent',
  props: ['dialog'],
  data () {
    return {
        contact_info: [           
            {
                id: 0,
                content: [
                    'Si tienes alguna pregunta o necesitas ayuda, no dudes en ponerte en contacto con nosotros. Estaremos encantados de ayudarte.',
                ]
            },
            // Puedes llamarnos al siguiente número de teléfono: XXX-XXX-XXX. Estamos disponibles de lunes a viernes de 9:00 a.m. a 6:00 p.m. Si por alguna razón no podemos atenderte en ese momento, déjanos un mensaje y te devolveremos la llamada lo antes posible.

            // También puedes enviarnos un correo electrónico a la dirección: info@ejemplo.com. Si nos envías un correo electrónico durante nuestro horario de atención, te responderemos en un plazo de 24 horas.

            // Si tienes preguntas específicas sobre nuestros productos o servicios, puedes consultar nuestra sección de preguntas frecuentes o enviarnos un mensaje a través de nuestro formulario de contacto en línea.

            // ¡Gracias de nuevo por visitarnos y esperamos poder ayudarte pronto!
            {
                id: 1,
                title: 'Teléfono',
                icon: 'mdi-phone',
                content: [
                    'Puedes llamarnos al siguiente <b>número de teléfono:</b> <b>+34 679 894 636</b>. Estamos disponibles de <b>lunes a viernes de 9:00 a.m. a 6:00 p.m</b>. Si por alguna razón no podemos atenderte en ese momento, déjanos un mensaje y te devolveremos la llamada lo antes posible.'
                ]
            },
            {
                id: 2,
                title: 'Correo electrónico',
                icon: 'mdi-email',
                content: [
                    'También puedes <b>enviarnos un correo electrónico a la dirección: info@slotspot.es</b>. Si nos envías un correo electrónico durante nuestro horario de atención, te responderemos en un plazo de 24 horas.'
            ]
            },
            {
                id: 3,
                title: 'Preguntas frecuentes',
                icon: 'mdi-help-circle',
                content: [
                    'Si tienes preguntas específicas sobre nuestros productos o servicios, <b>puedes consultar nuestra sección de preguntas frecuentes</b>.'
                ]
            },
            {
                id: 4,
                // title: 'Gracias',
                content: [
                    '¡Gracias de nuevo por visitarnos y esperamos poder ayudarte pronto!'
                ]
            },
        ],
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
