import Vue from 'vue'
import App from './App.vue'
import router from '@/plugins/router'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import store from '@/plugins/store' // path to vuetify export

Vue.config.productionTip = false

function cargarPlausible(url) {
  const scriptExistente = document.querySelector('script[src="https://plausible.io/js/script.js"]');
  if ((typeof scriptExistente?.dataset.domain === 'string' && scriptExistente.dataset.domain !== url) || typeof scriptExistente?.dataset.domain === 'undefined') {
    if (scriptExistente) document.head.removeChild(scriptExistente);

    const script = document.createElement('script');
    script.defer = true;
    script.dataset.domain = url;
    script.src = "https://plausible.io/js/script.js";
    document.head.appendChild(script);
  }
}

new Vue({
  el: '#app',
  router,
  vuetify,
  store,
  components: { App },
  template: '<App/>',
  watch: {
    $route (to, _from)  {
      if (to.path.startsWith('/businesses')) {
        cargarPlausible('slotspot.es/businesses');
      } else {
        cargarPlausible('slotspot.es');
      }
    }
  }
})

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname.startsWith('/businesses')) {
    cargarPlausible('slotspot.es/businesses');
  } else {
    cargarPlausible('slotspot.es');
  }
});


