import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        business: {},
    },
    getters: {
        business: state => state.business,
    },
    mutations: {
        setBusiness(state, business) {
            state.business = business
        },
        setCroppedUrlImage(state, url) {
            state.business.croppedUrlImage = url
        },
        setOriginalUrlImage(state, url) {
            state.business.originalUrlImage = url
        },
        setLogoUrlImage(state, url) {
            state.business.logoUrlImage = url
        },
    },
    actions: {
        async fetchBusiness({ commit }) {
            const response = await api.get(`business`)
            if (response.status === 200) {
                commit('setBusiness', response.data.business)
            }
        },
        async updateBusiness({ commit }, business) {
            const response = await api.put(`business`, business)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Información de empresa actualizada correctamente', color: 'success' })
                return response.data.business
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar la información', color: 'error' })
            }
        },
        async uploadBookingBgImage({ commit }, { blob, coordinates, originalImageFile }) {
            const formData = new FormData();
            formData.append('croppedImage', blob);
            formData.append('coordinates', JSON.stringify(coordinates));
            formData.append('originalImage', originalImageFile);

            const response = await api.post(`business/booking-bg-image`, formData)
            if (response.status === 200) {
                commit('setCroppedUrlImage', response.data.croppedUrlImage)
                commit('setOriginalUrlImage', response.data.originalUrlImage)
                commit('setSnackbar', { show: true, text: 'Imagen de fondo actualizada correctamente', color: 'success' })
            } else {
                commit('setSnackbar', { show: true, text: 'Error al actualizar la imagen de fondo', color: 'error' })
            }
        },
        async uploadLogoImage({ commit }, { blob }) {
            const formData = new FormData();
            formData.append('logoImage', blob);

            const response = await api.post(`business/logo-image`, formData)
            if (response.status === 200) {
                commit('setLogoUrlImage', response.data.logoUrlImage)
                commit('setSnackbar', { show: true, text: 'Imagen del logo actualizada correctamente', color: 'success' })
            } else {
                commit('setSnackbar', { show: true, text: 'Error al actualizar la imagen de fondo', color: 'error' })
            }
        },
        async removeBookingBgImage({ commit }) {
            const response = await api.delete(`business/booking-bg-image`)
            if (response.status === 200) {
                commit('setCroppedUrlImage', null)
                commit('setOriginalUrlImage', null)
                commit('setSnackbar', { show: true, text: 'Imagen de fondo eliminada correctamente', color: 'success' })
            } else {
                commit('setSnackbar', { show: true, text: 'Error al eliminar la imagen de fondo', color: 'error' })
            }
        }
    },
};
