<template>
    <!-- TODO: Dinamic for weeks start on sunday -->
    <v-card flat class="my-3">
        <v-card-title class="pa-0 text-center">
            <v-btn icon x-small @click="prevMonth">
                <v-icon small>mdi-chevron-left</v-icon>
            </v-btn>
            <span class="text-capitalize" :style="`font-size: ${Math.round(11/60*width.split('px')[0])}px;`">{{ date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' +  parseInt(date.getMonth() + 1)}} / {{ date.getYear().toString().substr(1,2) }}</span>
            <v-btn icon x-small @click="nextMonth">
                <v-icon small>mdi-chevron-right</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-0 mt-n1">
            <table class="mini-calendar caption ml-2" :style="`width: ${width}; height: ${height};`">
                <tr v-for="(week, i) in weeks" :key="'week' + i">
                    <td v-for="(day, j) in week" :key="'day' + j" :class="{'today': isToday(day.date), 'empty': !day.date, 'other-month': day.otherMonth, 'focus-day': isFocusDay(day.date)}">
                    </td>
                </tr>
            </table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'MicroCalendarComponent',
    props: {
        value: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '100%'
        },
        from: {
            type: String,
            default: ''
        },
        to: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            weeks: [],
            date: new Date(),
        }
    },
    computed: {
        height() {
            return `${this.width.split('px')[0] / 7 * this.weeks.length}px`;
        },
        currentMonth() {
            return this.date.getMonth();
        },
        fromDate() {
            return this.from == '' ? null : new Date(this.from);
        },
        toDate() {
            return this.to == '' ? null : new Date(this.to);
        },
    },
    watch: {
        currentMonth() {
            this.generateCalendar()
        },
    },
    methods: {
        getMonthDays(year, month) {
            let days = [];
            let date = new Date(year, month, 1);
            while (date.getDay() !== 1) {
                date.setDate(date.getDate() - 1);
                days.unshift({date: new Date(date), otherMonth: true});
            }
            date = new Date(year, month, 1);
            while (date.getMonth() === month) {
                days.push({date: new Date(date)});
                date.setDate(date.getDate() + 1);
            }
            while (date.getDay() !== 1) {
                days.push({date: new Date(date), otherMonth: true});
                date.setDate(date.getDate() + 1);
            }
            return days;
        },
        generateCalendar() {
            let days = this.getMonthDays(this.date.getFullYear(), this.date.getMonth());
            this.weeks = [];
            while (days.length) {
                this.weeks.push(days.splice(0, 7));
            }
        },
        setDate(date) {
            if (date && !date.otherMonth) {
                let newDate = new Date(this.date);
                newDate.setDate(date.getDate());
                this.date = newDate;
            }
        },
        isToday(day) {
            if (day && !day.otherMonth) {
                let today = new Date();
                return day.getDate() === today.getDate() && 
                    day.getMonth() === today.getMonth() && 
                    day.getFullYear() === today.getFullYear();
            }
            return false;
        },
        isFocusDay(day) {
            if (day>=this.fromDate && day<=this.toDate) {
                return true;
            }
            return false;
        },
        prevMonth() {
            let tempDate = new Date(this.date);
            tempDate.setMonth(this.date.getMonth() - 1);
            this.date = tempDate;
            this.generateCalendar();
        },
        nextMonth() {
            let tempDate = new Date(this.date);
            tempDate.setMonth(this.date.getMonth() + 1);
            this.date = tempDate;
            this.generateCalendar();
        },
    },
    mounted() {
        this.date =this.value == '' ? new Date() : new Date(this.value);
        this.generateCalendar();
    }
}
</script>

<style scoped>
.mini-calendar {
    border: 0 !important;
}

.mini-calendar tr td {
    text-align: center;
    vertical-align: middle;
    /* background: rgb(224, 224, 224); */
    /* background-color: rgb(0, 191, 165) !important; */
    background: rgb(179, 179, 179) !important;
    border-radius: 50%;
    border: 1px solid transparent;
}

.mini-calendar .today {
    background: rgb(128, 128, 128) !important;
}

.mini-calendar .focus-day {
    border-radius: 8px !important;
    /* background-color: rgb(0, 191, 165) !important; */
    background-color: rgb(255, 98, 98) !important;
}

.mini-calendar .other-month {
    background-color: transparent !important;
}
</style>
