<template>
    <v-dialog v-model="appointmentFormDialog" max-width="500" :fullscreen="$vuetify.breakpoint.mdAndDown">
        <v-card v-if="ready" class="d-flex flex-column">
            <v-card-title class="black--text px-6 py-3">
                <h3>
                    {{ appointmentId == 'new' ? 'Nueva reserva' : 'Editar reserva' }}
                </h3>
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-1" class="pa-5 px-6 font-weight-black elevation-0 mr-n5"
                        @click="remove" text small><span class="red--text">Eliminar</span></v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="appointment.bookings[0].clientId == null" class="black--text px-6 pb-0 pt-4">
                <h4 class="black--text">Cliente</h4>
                <v-combobox v-model="appointment.bookings[0].clientName" :items="clients" item-text="fullName" item-value="fullName" label="Nombre" outlined solo flat dense class="mt-2" hide-details :rules="[v => !!v || 'Campo requerido']">
                    <template v-slot:item="{ item }">
                        <v-list-item-content @click="fillExistingClient(item)">
                            <v-list-item-title class="black--text body-2">{{ item.fullName }}</v-list-item-title>
                            <v-list-item-subtitle class="grey--text caption">{{ item.email }}</v-list-item-subtitle>
                            <v-list-item-subtitle class="grey--text caption">{{ item.phoneNumber }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                </v-combobox>
                <v-text-field v-model="appointment.bookings[0].email" label="Email" outlined solo flat dense class="mt-2" hide-details :rules="[v => !!v || appointment.bookings[0].phoneNumber || 'Introduce el teléfono o el email']"></v-text-field>
                <!-- <v-text-field v-model="appointment.bookings[0].phoneNumber" label="Teléfono" outlined solo flat dense class="mt-2" hide-details></v-text-field> -->
                <PhoneInput 
                v-model="appointment.bookings[0].phoneNumber" 
                :phoneIso2.sync="appointment.bookings[0].phoneIso2"
                :rules="[v => !!v || appointment.bookings[0].email || 'Introduce el teléfono o el email']" 
                />
            </v-card-text>
            <div v-if="appointment.bookings[0].clientId">
                <v-card-text class="py-0 mb-n4">
                    <v-card class="px-1 pt-1 d-flex flex-row pb-0" flat @click="$router.push(`clients/details/${appointment.bookings[0].clientId}`)">
                        <v-avatar size="65" :color="getClientFromId(appointment.bookings[0].clientId).color" style="opacity: 0.7" class="mr-5 mt-2 rounded-lg white--text text-h5" tile>{{ getClientFromId(appointment.bookings[0].clientId).initials }}</v-avatar>
                        <v-card-text class="pl-0 pt-1 pb-0">
                            <p class="pa-0 ma-0 mt-1">
                                <span class="ml-2 font-weight-bold subtitle-2">{{ getClientFromId(appointment.bookings[0].clientId).fullName }}</span>
                            </p>
                            <p class="pa-0 ma-0 mt-n1">
                                <span class="ml-2 font-weight-light subtitle-2">{{ getClientFromId(appointment.bookings[0].clientId).email }}</span>
                            </p>
                            <p class="pa-0 mt-n1">
                                <span class="ml-2 font-weight-light subtitle-2">{{ getClientFromId(appointment.bookings[0].clientId).phoneNumber }}</span>
                            </p>
                        </v-card-text>
                        <v-card-actions v-if="appointmentId == 'new'" class="ml-auto">
                            <v-btn icon @click="removeClientInfo">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-card-text>
                <v-divider class="mt-4"></v-divider>
            </div>
            <v-card-text class="black--text px-6 pb-0 mt-3">
                <h4 class="black--text">Asignar servicio</h4>
                <v-autocomplete v-model="appointment.serviceId" :items="services" item-text="name" item-value="id" label="Servicio" solo flat outlined dense class="mt-1" hide-details :rules="[v => !!v || 'Elige un servicio']">
                    <template v-slot:selection="{ item }">
                        <v-chip small outlined :color="`${item.color?.split(' ')[0]} lighten-2`" label class="my-1 py-4 rounded-lg">
                            <v-avatar :color="`${item.color?.split(' ')[0]} lighten-2`" class="grey--text text--lighten-4 rounded-lg ml-n2 mr-2" tile >{{ item.initials }}</v-avatar>
                            {{ item.name }}
                        </v-chip>
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-card-text class="black--text px-6 pb-0 mt-3">
                <h4 class="black--text">Asignar empleado</h4>
                <v-autocomplete v-model="appointment.employeeId" :items="employees" item-text="fullName" item-value="id" label="Empleado" solo flat outlined dense class="mt-1" hide-details :rules="[v => !!v || 'Elige un empleado']">
                    <template v-slot:selection="{ item }">
                        <v-chip small outlined :color="`${item.color?.split(' ')[0]} lighten-2`" label class="my-1 py-4 rounded-lg">
                            <v-avatar :color="`${item.color?.split(' ')[0]} lighten-2`" class="grey--text text--lighten-4 rounded-lg ml-n2 mr-2" tile >{{ item.initials }}</v-avatar>
                            {{ item.fullName }}
                        </v-chip>
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-card-text class="black--text px-6 pb-0 mt-3">
                <v-row>
                    <v-col cols="12" lg="4">
                        <h4 class="black--text mb-1">Fecha</h4>
                        <!-- <v-autocomplete v-model="appointment.date" label="Día" outlined solo flat dense :items="Array.from({ length:32 }).map((v, idx) => idx == 0 ? { value: null, text: '--' } : { value: idx, text: idx })">
                        </v-autocomplete> -->
                        <v-menu
                        ref="menuDate"
                        v-model="menuDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                class="body-1"
                                v-model="startDate" 
                                outlined 
                                solo 
                                flat 
                                dense 
                                readonly
                                append-icon="mdi-calendar"
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                :rules="[v => !!v || 'Campo requerido']"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="startDate"
                                locale="es"
                                color="primary"
                                @input="menuDate = false; hasChanged = true;"
                                first-day-of-week="1"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <h4 class="black--text mb-1">Hora</h4>
                        <v-menu ref="menuStartTime" v-model="menuStartTime" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y max-width="290px" min-width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field class="pa-0 timetable-input" v-model="startTime"
                                    append-icon="mdi-clock-outline" v-bind="attrs" v-on="on" hide-details outlined
                                    dense @change="hasChanged = true" readonly :rules="[v => !!v || 'Campo requerido']"></v-text-field>
                            </template>
                            <v-time-picker v-if="menuStartTime" v-model="startTime" format="24hr" min="00:00"
                                max="23:59" color="primary"></v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <h4 class="black--text mb-1">Duración</h4>
                        <v-autocomplete v-model="duration" label="Duración" outlined solo flat dense :items="timingValues.timeSlotFrequencyValues" item-text="label" item-value="value" append-icon="mdi-timer" hide-details :rules="[v => !!v || 'Campo requerido']">
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text class="black--text px-6 mt-3" v-if="appointmentId !== 'new'">
                <h4 class="black--text">Estado de la reserva</h4>
                <v-autocomplete v-model="appointment.bookings[0].status" :items="statuses" item-text="text" item-value="value" label="Estado" solo flat outlined dense class="mt-1" hide-details>
                    <template v-slot:selection="{ item }">
                        <v-chip :color="`${item.color}`" label class="my-1 py-4 rounded-lg white--text">
                            <!-- <v-avatar :color="`${item.color}`" class="grey--text text--lighten-4 rounded-lg ml-n2 mr-2" tile > -->
                                <v-icon color="white" left>{{ item.icon }}</v-icon>    
                            <!-- </v-avatar> -->
                            {{ item.text }}
                        </v-chip>
                    </template>
                </v-autocomplete>
            </v-card-text>
            <v-spacer class=" flex-grow-1"></v-spacer>
            <v-divider :class="{'mt-4': appointmentId === 'new'}"></v-divider>
            <v-card-actions class="py-4 d-flex flex-column align-end">
                <div>
                    <v-btn color="grey lighten-1" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                        @click="close" text><span
                            class="grey--text text--darken-1">Cancelar</span></v-btn>
                    <v-btn color="teal accent-4" class="mr-3 pa-5 px-6 font-weight-black elevation-0"
                        @click="save" :dark="hasChanged" :disabled="!hasChanged">Guardar</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<!-- TODO: añadir posibilidad de repetir evento -->

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { BOOKING_STATUSES } from '@/consts'

import PhoneInput from '@/components/common/PhoneInput.vue'

export default {
    name: 'AppointmentFormDialogComponent',
    components: {
        PhoneInput
    },
    props: {
        value: {
            Boolean,
            default: false
        },
        appointmentId: {
            type: String,
            default: 'new'
        },
    },
    data() {
        return {
            hasChanged: false,
            menuDate: false,
            menuStartTime: false,
            appointment: {
                bookings: [
                    {
                        clientId: null,
                        status: BOOKING_STATUSES.CONFIRMED
                    }
                ],
                start: '',
                end: '',
            },
            defaultAppointment: '',
            statuses: [
                {
                    value: BOOKING_STATUSES.PENDING,
                    text: 'Pendiente',
                    icon: 'mdi-clock-outline',
                    color: 'grey'
                },
                {
                    value: BOOKING_STATUSES.CONFIRMED,
                    text: 'Confirmada',
                    icon: 'mdi-check-circle-outline',
                    color: 'teal lighten-2'
                },
                {
                    value: BOOKING_STATUSES.CLIENT_ABSENT,
                    text: 'Cliente ausente',
                    icon: 'mdi-close-circle-outline',
                    color: 'orange lighten-2'
                },
                {
                    value: BOOKING_STATUSES.CLIENT_ARRIVED,
                    text: 'Cliente presente',
                    icon: 'mdi-check-circle',
                    color: 'teal lighten-2'
                },
                {
                    value: BOOKING_STATUSES.CANCELLED,
                    text: 'Cancelada',
                    icon: 'mdi-close-circle',
                    color: 'red lighten-2'
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['clients', 'employees', 'services', 'appointments', 'timingValues']),
        appointmentFormDialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        serializedAppointment() {
            return JSON.stringify(this.appointment)
        },
        ready() {
            return this.employees.length > 0 && this.services.length > 0 && this.timingValues != {}
        },
        start() {
            return moment(this.appointment.start)
        },
        end() {
            return moment(this.appointment.end)
        },
        startDate: {
            get() {
                return this.start.format('YYYY-MM-DD');
            },
            set(value) {
                const duration = parseInt(this.duration)
                const updatedStart = moment(value);
                const oldStart = moment(this.appointment.start);

                // Mantenemos la misma hora, pero cambiamos la fecha
                updatedStart.hour(oldStart.hour()).minute(oldStart.minute()).second(oldStart.second());

                this.appointment.start = updatedStart.format();
                this.duration = duration
            }
        },
        startTime: {
            get() {
                return this.start.format('HH:mm');
            },
            set(value) {
                const duration = parseInt(this.duration)
                const oldStart = this.start;
                const [hours, minutes] = value.split(':');

                // Cambiamos la hora, pero mantenemos la misma fecha
                oldStart.hour(hours).minute(minutes);

                this.appointment.start = oldStart.format();
                this.duration = duration
            }
        },
        duration: {
            get() {
                return this.end.diff(this.start, 'seconds')
            },
            set(value) {
                const start = moment(this.start)
                this.appointment.end = start.add(value * 1000).format()
            }
        },
        serviceId() {
            return this.appointment.serviceId
        },
    },
    watch: {
        appointmentFormDialog(val) {
            if (val) {
                if (this.appointmentId == 'new') {
                    this.appointment.start = moment().set({hour: 0, minute: 0, second: 0}).format()
                    this.appointment.end = moment().set({hour: 0, minute: 0, second: 0}).format()
                    this.duration = this.timingValues.timeSlotFrequencyValues[0].value
                    this.appointment.employeeId = this.employees[0].id
                } else {
                    this.appointment = Object.assign({}, this.getAppointmentFromId(this.appointmentId))
                }
                this.$nextTick(() => {
                    this.defaultAppointment = JSON.stringify(this.appointment)
                })
            }
        },
        serializedAppointment() {
            this.hasChanged = this.serializedAppointment !== this.defaultAppointment && this.defaultAppointment !== ''
        },
        serviceId() {
            const service = this.services.find(service => service.id == this.serviceId)
            if (service) {
                this.duration = service.duration
            }
        },
    },
    async created() {
        if (this.clients?.length == 0) await this.fetchClients()
        if (this.employees?.length == 0) await this.fetchEmployees()
        if (this.services?.length == 0) await this.fetchServices()
        if (this.timingValues) await this.fetchTimingValues()
    },
    methods: {
        ...mapActions(['fetchClients', 'fetchEmployees', 'fetchServices', 'fetchAppointment', 'fetchAppointments', 'fetchTimingValues', 'createAppointment', 'updateAppointment', 'deleteAppointment']),
        getMonthName (number, capitalize=false) {
            if (number < 1 || number > 12) {
                throw new Error('Invalid month number');
            }
            if (capitalize) {
                return moment().locale('es').month(number - 1).format('MMMM').charAt(0).toUpperCase() + moment().locale('es').month(number - 1).format('MMMM').slice(1);
            }
            return moment().locale('es').month(number - 1).format('MMMM');
        },
        getAppointmentFromId(id) {
            return this.appointments.find(appointment => appointment.id == id)
        },
        getClientFromId(id) {
            return this.clients.find(client => client.id == id)
        },
        fillExistingClient(client) {
            // const client = this.clients.find(client => client.fullName == this.appointment.bookings[0].clientName)
            if (client) {
                this.appointment.bookings[0].clientId = client.id
                this.appointment.bookings[0].email = client.email
                this.appointment.bookings[0].phoneNumber = client.phoneNumber
            }
        },
        removeClientInfo() {
            this.appointment.bookings[0].clientId = null
            this.appointment.bookings[0].email = ''
            this.appointment.bookings[0].phoneNumber = ''
        },
        close() {
            this.appointmentFormDialog = false
            this.resetValues()
        },
        resetValues() {
            this.hasChanged = false
            this.appointment = Object.assign({
                bookings: [
                    {
                        clientId: null,
                    }
                ],
                start: '',
                end: '',
            })
            this.defaultAppointment = ''
        },
        async save() {
            if (this.appointmentId == 'new') {
                await this.createAppointment(this.appointment)
            } else {
                await this.updateAppointment(this.appointment)
            }
            this.close()
            await this.fetchClients()
            await this.fetchAppointments()
        },
        async remove() {
            await this.deleteAppointment(this.appointmentId)
            this.close()
            await this.fetchAppointments()
        }
    },
    
}

</script>
<style scoped>

</style>