<template>     
    <div>
        <v-toolbar flat height="80" class="px-lg-4">
            <h2>
                Facturación
            </h2>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Facturas</h4>
                    <!-- <v-spacer></v-spacer>
                    <v-btn plain color="primary" class="font-weight-bold pr-0" @click="addressDialog=true">Agregar dirección</v-btn> -->
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6">
                    No tienes ninguna factura. Las facturas aparecerán aquí automáticamente después de cada pago.
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>

export default {
    name: 'BillingView',
    components: {
    },
    data() {
        return {

        }
    },
    computed: {
        
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
