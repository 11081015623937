import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        clients: [],
    },
    getters: {
        clients: state => state.clients,
    },
    mutations: {
        setClients(state, clients) {
            state.clients = clients
        },
    },
    actions: {
        async fetchClients({ commit }) {
            const response = await api.get(`clients`, { params: {} })
            if (response.status === 200) {
                commit('setClients', response.data.clients)
            }
        },
        async fetchClient({ commit }, id) {
            const response = await api.get(`clients/${id}`, { params: {} })
            if (response.status === 200) {
                return response.data.client
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener el cliente', color: 'error' })
            }
        },
        async createClient({ commit }, client) {
            const response = await api.post(`clients`, client)
            if (response.status === 201) {
                commit('setSnackbar', { show: true, text: 'Cliente creado correctamente', color: 'success' })
                return response.data.client
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al crear el cliente', color: 'error' })
            }
        },
        async updateClient({ commit }, client) {
            const response = await api.put(`clients/${client.id}`, client)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Cliente actualizado correctamente', color: 'success' })
                return response.data.client
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar el cliente', color: 'error' })
            }
        },
        async deleteClient({ commit }, id) {
            const response = await api.delete(`clients/${id}`, { params: {} })
            if (response.status === 204) {
                commit('setSnackbar', { show: true, text: 'Cliente eliminado correctamente', color: 'success' })
                return true
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al eliminar el cliente', color: 'error' })
                return false
            }
        },
    },
};
