export const BOOKING_STATUSES = {
    PENDING: 0,
    CONFIRMED: 1,
    CANCELLED: 2,
    CLIENT_ABSENT: 3,
    CLIENT_ARRIVED: 4,
};

export const BOOKING_ORIGINS = {
    BOOKING_PAGE: 'web',
    CALENDAR: 'calendar',
};