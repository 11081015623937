import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        notificationConfig: {},
        timingValues: {}
    },
    getters: {
        notificationConfig: state => state.notificationConfig,
    },
    mutations: {
        setNotificationConfig(state, notificationConfig) {
            state.notificationConfig = notificationConfig
        },
    },
    actions: {
        async fetchNotificationConfig({ commit }) {
            const response = await api.get(`notification-config`, { params: {} })
            if (response.status === 200) {
                const notificationConfig = response.data.notificationConfig
                commit('setNotificationConfig', notificationConfig)
                return notificationConfig
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener los ajustes de notificaciones', color: 'error' })
            }
        },
        async updateNotificationConfig({ commit }, notificationConfig) {
            const response = await api.put(`notification-config`, notificationConfig)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Ajustes de notificaciones actualizados correctamente', color: 'success' })
                return response.data.notificationConfig
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar los ajustes de notificaciones', color: 'error' })
            }
        },
    },
};
