/* eslint-disable indent */
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'fa'
  },
  theme: {
    themes: {
        light: {
            // primary: '#1565C0',
            // primary: '#1DE9B6', // Verde menta clarito
            primary: '#00BFA5',
            // primary: '#F9A825', // Naranja
            // primary: '#0ff', // cmy(255, 255, 255)
            secondary: '#00796B',
            lighten: '#4DB6AC',
            darken: '#004D40',
            tertiary: '#b0bec5',
            accent: '#1565C0',
            error: '#b71c1c',
            info: '#5e5e5e',
            anchor: '#4e88a7'
      }
    }
  }
}

export default new Vuetify(opts)
