<template>
    <v-card flat class="my-5">
        <v-card-title class="px-1 py-0">
            <h5 class="text-capitalize pl-1">{{ new Intl.DateTimeFormat('default', { month: 'long' }).format(date) }} {{ date.getFullYear() }}</h5>
            <v-spacer></v-spacer>
            <v-btn icon small @click="prevMonth">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn icon small @click="nextMonth">
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text class="pa-0">
            <table width="100%" class="mini-calendar caption">
                <tr class="font-weight-bold">
                    <td>Lu</td>
                    <td>Ma</td>
                    <td>Mi</td>
                    <td>Ju</td>
                    <td>Vi</td>
                    <td>Sa</td>
                    <td>Do</td>
                </tr>
                <tr v-for="(week, i) in weeks" :key="'week' + i" :class="{ 'focus-week': isFocusWeek(week) }">
                    <td v-for="(day, j) in week" :key="'day' + j" :class="{'today': isToday(day.date), 'empty': !day.date, 'other-month': day.otherMonth, 'focus-day': isFocusDay(day.date)}" @click="setDate(day.date)">
                        {{ day.date ? day.date.getDate() : '' }}
                    </td>
                </tr>
            </table>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'MiniCalendarComponent',
    props: {
        value: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'category'
        }
    },
    data() {
        return {
            weeks: []
        }
    },
    computed: {
        date: {
            get() {
                return this.value == '' ? new Date() : new Date(this.value);
            },
            set(value) {
                this.$emit('input', value.toISOString().split('T')[0]);
            }
        },
        currentMonth() {
            return this.date.getMonth();
        },
    },
    watch: {
        currentMonth() {
            this.generateCalendar()
        },
    },
    methods: {
        getMonthDays(year, month) {
            let days = [];
            let date = new Date(year, month, 1);
            while (date.getDay() !== 1) {
                date.setDate(date.getDate() - 1);
                days.unshift({date: new Date(date), otherMonth: true});
            }
            date = new Date(year, month, 1);
            while (date.getMonth() === month) {
                days.push({date: new Date(date)});
                date.setDate(date.getDate() + 1);
            }
            while (date.getDay() !== 1) {
                days.push({date: new Date(date), otherMonth: true});
                date.setDate(date.getDate() + 1);
            }
            return days;
        },
        generateCalendar() {
            let days = this.getMonthDays(this.date.getFullYear(), this.date.getMonth());
            this.weeks = [];
            while (days.length) {
                this.weeks.push(days.splice(0, 7));
            }
        },
        setDate(date) {
            if (date && !date.otherMonth) {
                let newDate = new Date(this.date);
                newDate.setDate(date.getDate());
                this.date = newDate;
            }
        },
        prevMonth() {
            let tempDate = new Date(this.date);
            tempDate.setMonth(this.date.getMonth() - 1);
            this.date = tempDate;
            this.generateCalendar();
        },
        nextMonth() {
            let tempDate = new Date(this.date);
            tempDate.setMonth(this.date.getMonth() + 1);
            this.date = tempDate;
            this.generateCalendar();
        },
        isToday(day) {
            if (day && !day.otherMonth) {
                let today = new Date();
                return day.getDate() === today.getDate() && 
                    day.getMonth() === today.getMonth() && 
                    day.getFullYear() === today.getFullYear();
            }
            return false;
        },
        isFocusDay(day) {
            if (day && !day.otherMonth && this.type == 'category') {
                return day.getDate() === this.date.getDate() && 
                    day.getMonth() === this.date.getMonth() && 
                    day.getFullYear() === this.date.getFullYear();
            }
            return false;
        },
        getWeek(date) {
            let tempDate = new Date(date.getTime());
            tempDate.setDate(tempDate.getDate() + 3 - (tempDate.getDay() + 6) % 7);
            let jan4 = new Date(tempDate.getFullYear(), 0, 4);
            return 1 + Math.round(((tempDate - jan4) / 86400000 - 3 + (jan4.getDay() + 6) % 7) / 7);
        },
        isFocusWeek(week) {
            if (this.type == 'week') {
                let date = this.date;
                return week.some(day => day.date && 
                    !day.otherMonth && 
                    this.getWeek(day.date) === this.getWeek(date) && 
                    day.date.getFullYear() === date.getFullYear());
            }
            return false;
        },
    },
    mounted() {
        this.generateCalendar();
    }
}
</script>

<style scoped>
h5 {
    font-size: 1rem !important;
    font-weight: bold !important;
}
.mini-calendar {
    border: 0 !important;
    border-collapse: collapse !important;
}

.mini-calendar tr td {
    width: 14.28571428571429%;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
}

.mini-calendar .today {
    color: rgb(0, 191, 165) !important;
    font-weight: bold;
}

.mini-calendar .focus-week {
    background-color: rgba(0, 191, 165, 0.25) !important;
}

.mini-calendar .focus-week td:first-child {
    border-radius: 8px 0 0 8px !important;
}

.mini-calendar .focus-week td:last-child {
    border-radius: 0 8px 8px 0 !important;
}

.mini-calendar .focus-day {
    border-radius: 8px !important;
    background-color: rgba(0, 191, 165, 0.25) !important;
}

.mini-calendar .other-month {
    color: rgba(0, 0, 0, 0.25) !important;
}
</style>
