<template>
    <div>
        <v-navigation-drawer app class="translucient-white pt-2" left clipped width="300">
            <v-list nav :value="selectedItem">
                <v-list-group
                    v-for="(item, itemIdx) in navigationItems"
                    :key="`item-${itemIdx}`"
                    :prepend-icon="item.icon"
                    append-icon="mdi-chevron-down"
                    no-action
                    color="grey darken-3"
                    @click.stop="linkTo(item.path)"
                    :value="selectedItem == itemIdx"
                    :disabled="selectedItem == itemIdx"
                >
                    <template v-slot:activator>
                        <v-list-item-content>
                            <v-list-item-title v-text="item.title" class="font-weight-bold body-2"></v-list-item-title>
                        </v-list-item-content>
                    </template>
                    <v-list-item
                    v-for="(child, childIdx) in item.items"
                    :key="`child-${itemIdx}-${childIdx}`"
                    @click.stop="linkTo(child.path)"
                    :input-value="`${selectedItem}-${selectedSubitem}` == `${itemIdx}-${childIdx}`"
                    color="teal accent-4"
                    >
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-bold body-2">{{ child.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
            <v-divider class="mt-3"></v-divider>
        </v-navigation-drawer>
        <v-main app>
            <router-view/>
        </v-main>
    </div>
</template>

<script>

export default {
    name: 'BusinessLayout',
    components: {

    },
    data() {
        return {
            // selectedItem: 0,
        }
    },
    computed: {
        selectedItem() {
            for (let i = 0; i < this.navigationItems.length; i++) {
                const item = this.navigationItems[i]
                if (this.$route.path.includes(item.path)) {
                    return i
                }
                for (let j = 0; j < item.items.length; j++) {
                    const child = item.items[j]
                    if (this.$route.path.includes(child.path)) {
                        return i
                    }
                }
            }
            return 0
        },
        selectedSubitem() {
            const item = this.navigationItems[this.selectedItem]
            if (item) {
                return item.items.findIndex(child =>  this.$route.path.includes(child.path))
            }
            return 0
        },
        navigationItems() {
            const businessId = localStorage.getItem('businessId')
            return [
                {
                    icon: 'mdi-store',
                    title: 'Mi empresa',
                    path: `/businesses/${businessId}/business/details`,
                    items: [
                        { 
                            title: 'Perfil de la empresa',
                            path: `/businesses/${businessId}/business/details`,
                        },
                        { 
                            title: 'Facturación',
                            path: `/businesses/${businessId}/business/billing`,
                        },
                    ],
                },
                {
                    icon: 'mdi-calendar-clock',
                    title: 'Planificación',
                    path: `/businesses/${businessId}/business/services`,
                    items: [
                        { 
                            title: 'Servicios', 
                            path: `/businesses/${businessId}/business/services`,
                        },
                        { 
                            title: 'Ajustes de reservas',
                            path: `/businesses/${businessId}/business/rules`,
                        },
                        { 
                            title: 'Equipo',
                            path: `/businesses/${businessId}/business/employees`,
                        },
                        { 
                            title: 'Ausencia laboral',
                            path: `/businesses/${businessId}/business/vacations`,
                        },
                        { 
                            title: 'Notificaciones',
                            path: `/businesses/${businessId}/business/notifications`,
                        },
                    ],
                },
            ]
        }
    },
    mounted() {

    },
    methods: {
        linkTo(path) {
            this.$router.push(path)
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
