export default {
    namespaced: false,
    state: {
        snackbar: {
            show: false,
            text: '',
            color: 'info',
            timeout: 6000,
        },
    },
    getters: {
        snackbar: state => state.snackbar,
    },
    mutations: {
        setSnackbar(state, { show, text, color, timeout }) {
            state.snackbar.show = show
            state.snackbar.text = text
            state.snackbar.color = color ?? 'success'
            state.snackbar.timeout = timeout ?? 6000
        },
    },
    actions: {
        // async setSnackbar({ commit }, value) {
        //     api.get(`services`, { params: {} })
        //     .then(response => {
        //         if (response.status === 200) {
        //             commit('setServices', response.data.services)
        //         }
        //     })
        // },
    },
};
