<!-- TODO: No añadir botón de añadir categoría -->
<template>
    <div>
        <v-toolbar flat height="80" class="px-lg-4">
            <v-btn @click="$router.back()" icon><v-icon dense class="mt-1">mdi-chevron-left</v-icon></v-btn>
            <h2 :style="$vuetify.breakpoint.mdAndDown && 'font-size: 20px;'" class="text-wrap">
                {{ employeeId !== 'new' ? employee.fullName : 'Añadir empleado' }}
            </h2>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="py-5 px-6 rounded-lg" small :disabled="!hasChanged" @click="save">
                <v-icon left>mdi-content-save</v-icon>
                Guardar
            </v-btn>

        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Detalles del empleado</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6">
                    <h4 class="black--text">Nombre del empleado</h4>
                    <v-text-field v-model="employee.fullName" label="Nombre completo" outlined solo flat dense
                        class="mt-2"></v-text-field>
                    <h4 class="black--text">Servicios asociados</h4>
                    <v-autocomplete v-model="employee.services" :items="services" item-text="name" item-value="id"
                        label="Servicios" solo flat outlined dense class="mt-2" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip :color="`${item.color?.split(' ')[0]} lighten-3`" small label
                                class="rounded-lg mr-1">
                                {{ item.name }}
                            </v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item @click="allServices = !allServices">
                                <v-list-item-action>
                                    <v-checkbox v-model="allServices" readonly color="primary"></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Todos los servicios</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <!-- <template v-slot:item="{ item }">
                            <v-chip :color="item.value" small label class="rounded-lg mr-3">
                            </v-chip>
                            <span class="font-weight-regular">{{ item.text }}</span>
                        </template> -->
                    </v-autocomplete>
                    <h4 class="black--text">Email</h4>
                    <v-text-field v-model="employee.email" label="Email" outlined solo flat dense class="mt-2"
                        type="email"></v-text-field>
                    <h4 class="black--text">Color</h4>
                    <v-autocomplete v-model="employee.color" :items="colors" item-text="text" item-value="value"
                        label="Color" solo flat outlined dense class="mt-2">
                        <template v-slot:selection="{ item }">
                            <v-chip :color="item.value" small label class="rounded-lg mr-3">
                            </v-chip>
                            <span>{{ item.text }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-chip :color="item.value" small label class="rounded-lg mr-3">
                            </v-chip>
                            <span class="font-weight-regular">{{ item.text }}</span>
                        </template>
                    </v-autocomplete>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Notificaciones y permisos</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-3 pb-0 px-6">
                    <div class="d-flex flex-row justify-center align-center">
                        <h4 class="black--text">Notificar nuevas reservas por correo electrónico</h4>
                        <v-spacer></v-spacer>
                        <v-switch v-model="employee.notifyNewBookingsByEmail" color="teal accent-4" inset
                            class="mr-3"></v-switch>
                    </div>
                    <v-divider></v-divider>
                    <div class="d-flex flex-row justify-center align-center">
                        <h4 class="black--text">Permitir administración personal de cuenta y gestión de reservas propias
                        </h4>
                        <v-spacer></v-spacer>
                        <v-switch v-model="employee.allowSelfBookingManagement" color="teal accent-4" inset
                            class="mr-3"></v-switch>
                    </div>
                </v-card-text>
            </v-card>
            <v-row v-if="this.employeeId !== 'new'">
                <v-col cols="12" class="d-flex justify-end">
                    <v-btn color="red" class="py-5 px-6 rounded-lg text-none" small @click="handleClickDelete" plain>
                        <v-icon left>mdi-trash-can-outline</v-icon>
                        Eliminar empleado
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
        v-model="confirmDeleteDialog"
        max-width="350"
        >
            <v-card class="py-2 px-1">
                <v-card-title><h4>Eliminar empleado</h4></v-card-title>
                <v-card-text>¿Estás seguro de que quieres eliminar este empleado?</v-card-text>
                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        plain
                        @click="confirmDeleteDialog = false"
                        small
                        class="px-4 py-4 plain-text"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        @click="remove"
                        small
                        class="px-4 py-4 white--text plain-text"
                    >
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <!-- TODO: Poder añadir horario personal -->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'EmployeesFormView',
    props: {
        employeeId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            hasChanged: false,
            employee: {
                services: [],
            },
            defaultEmployee: "",
            confirmDeleteDialog: false
        }
    },
    computed: {
        ...mapGetters(['employees', 'colors', 'services']),
        allServices: {
            get() {
                return this.employee.services.length === this.services.length
            },
            set(value) {
                if (value) {
                    this.employee.services = Object.assign([], this.services)
                } else {
                    this.employee.services = []
                }
            }
        },
        serializedEmployee() {
            return JSON.stringify(this.employee)
        }
    },
    watch: {
        serializedEmployee() {
            this.hasChanged = this.serializedEmployee !== this.defaultEmployee && this.defaultEmployee !== ''
        }
    },
    async created() {
        if (this.services.length === 0) this.fetchServices()

        if (this.employeeId === 'new') {
            this.defaultEmployee = JSON.stringify(this.client)
        }
        else {
            this.employee = await this.fetchEmployee(this.employeeId)
            this.$nextTick(() => {
                this.defaultEmployee = JSON.stringify(this.employee)
            })
        }
    },
    methods: {
        ...mapActions(['fetchEmployee', 'fetchServices', 'createEmployee', 'updateEmployee', 'deleteEmployee']),
        ...mapMutations(['setEmployees']),
        handleClickDelete() {
            this.confirmDeleteDialog = true
        },
        async save() {
            if (this.employeeId === 'new') {
                await this.createEmployee(this.employee)
            } else {
                await this.updateEmployee(this.employee)
            }
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/business/employees`)
        },
        async remove() {
            await this.deleteEmployee(this.employeeId)
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/business/employees`)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
