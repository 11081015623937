import api from "@/services/api";


// const TIME_LABELS = {
//     join: "and",
//     notSend: "Not send",
//     atAnyTime: "At any time",
//     never: "Never",
//     minute: "minute",
//     minutes: "minutes",
//     hour: "hour",
//     hours: "hours",
//     day: "day",
//     days: "days",
// }


const TIME_LABELS = {
    join: "y",
    notSend: "No enviado",
    atAnyTime: "En cualquier momento",
    never: "Nunca",
    minute: "minuto",
    minutes: "minutos",
    hour: "hora",
    hours: "horas",
    day: "día",
    days: "días",
}

const TIME_UNITS = [
    { limit: 3600, value: 60, single: TIME_LABELS.minute, plural: TIME_LABELS.minutes },
    { limit: 86400, value: 3600, single: TIME_LABELS.hour, plural: TIME_LABELS.hours },
    { limit: 31536000, value: 86400, single: TIME_LABELS.day, plural: TIME_LABELS.days }
];


export default {
    namespaced: false,
    state: {
        bookingRule: {},
        timingValues: {}
    },
    getters: {
        bookingRule: state => state.bookingRule,
        timingValues: state => state.timingValues,
        // Function to convert seconds into a readable label
        secondsToLabel: (state, getters) => (seconds, prev=null) => {
            let returnedLabel = prev ? `${prev} ${TIME_LABELS.join} ` : ''
            for (let unit of TIME_UNITS) {
                if (seconds < unit.limit) {
                    const count = Math.floor(seconds / unit.value)
                    returnedLabel = `${returnedLabel} ${count} ${count === 1 ? unit.single : unit.plural}`
                    if (seconds % unit.value < TIME_UNITS[0].value) return returnedLabel
                    else return getters.secondsToLabel(seconds % unit.value, returnedLabel)
                }
            }
            return TIME_LABELS.atAnyTime
        },
        // Function to get the label from the value
        getSlotFormattedTime: (state, getters) => slotInSeconds => {
            // Especialidades
            if (slotInSeconds === 0) return TIME_LABELS.never;
            if (slotInSeconds === 31536000) return TIME_LABELS.atAnyTime;  // Si se trata de un año en segundos
            
            return getters.secondsToLabel(slotInSeconds);
        }
    },
    mutations: {
        setBookingRule(state, bookingRule) {
            state.bookingRule = bookingRule
        },
        setTimingValues(state, timingValues) {
            state.timingValues = timingValues
        }
    },
    actions: {
        async fetchBookingRule({ commit }) {
            const response = await api.get(`booking-rule`, { params: {} })
            if (response.status === 200) {
                return response.data.bookingRule
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener los ajustes de reserva', color: 'error' })
            }
        },
        async updateBookingRule({ commit }, bookingRule) {
            const response = await api.put(`booking-rule`, bookingRule)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Ajustes de reserva actualizados correctamente', color: 'success' })
                return response.data.bookingRule
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar los ajustes de resrva', color: 'error' })
            }
        },
        async fetchTimingValues({ commit }) {
            const response = await api.get(`booking-rule/timing-values`, { params: {} })
            if (response.status === 200) {
                const timingValues = response.data.timingValues
                let timingValuesFormatted = {}
                for (let key in timingValues) {
                    timingValuesFormatted[key] = timingValues[key].map(slot => {
                        const label = this.getters.getSlotFormattedTime(slot)
                        return {
                            value: slot,
                            label: label === TIME_LABELS.never && key === 'reminderTimeBeforeValues' ? TIME_LABELS.notSend : label
                        }
                    })
                }
                commit('setTimingValues', timingValuesFormatted)
            }
        },
    },
};
