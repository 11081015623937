import api from "@/services/api";

const preprocessService = (service) => {
    const preprocessedService = { ...service }
    const price = parseInt(parseFloat(preprocessedService.price) * 100)
    preprocessedService.price = isNaN(price) ? 0 : price
    return preprocessedService
}

const postprocessService = (service) => {
    const price = parseFloat(service.price/100)
    service.price = isNaN(price) ? 0 : price
    return service
}

export default {
    namespaced: false,
    state: {
        services: [],
    },
    getters: {
        services: state => state.services,
        getServiceDuration: (state) => (id) => {
            const service = state.services.find(service => service.id === id)
            if (service) {
                const duration = service.duration
                const hours = Math.floor(duration / 3600)
                const minutes = Math.floor((duration % 3600) / 60)
                return { hours, minutes }
            }
            return {
                hours: 0,
                minutes: 0,
            }
        }
    },
    mutations: {
        setServices(state, services) {
            state.services = services
        },
        setServiceDuration(state, { id, hours, minutes }) {
            const service = state.services.find(service => service.id === id)
            if (service) {
                service.duration = hours * 60 + minutes
            }
        }
    },
    actions: {
        async fetchServices({ commit }) {
            api.get(`services`, { params: {} })
            .then(response => {
                if (response.status === 200) {
                    const services = response.data.services.map(postprocessService)
                    commit('setServices', services)
                }
            })
        },
        async fetchService({ commit }, id) {
            const response = await api.get(`services/${id}`, { params: {} })
            if (response.status === 200) {
                return postprocessService(response.data.service)
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener el servicio', color: 'error' })
            }
        },
        async createService({ commit }, service) {
            const sendService = preprocessService(service)
            const response = await api.post(`services`, sendService)
            if (response.status === 201) {
                commit('setSnackbar', { show: true, text: 'Servicio creado correctamente', color: 'success' })
                return response.data.service
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al crear el servicio', color: 'error' })
            }
        },
        async updateService({ commit }, service) {
            const sendService = preprocessService(service)
            const response = await api.put(`services/${service.id}`, sendService)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Servicio actualizado correctamente', color: 'success' })
                return postprocessService(response.data.service)
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar el servicio', color: 'error' })
            }
        },
        async deleteService({ commit }, id) {
            const response = await api.delete(`services/${id}`, { params: {} })
            if (response.status === 204) {
                commit('setSnackbar', { show: true, text: 'Servicio eliminado correctamente', color: 'success' })
                return true
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al eliminar el servicio', color: 'error' })
                return false
            }
        },
    },
};
