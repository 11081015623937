<template>
    <v-main app>
        <v-container class="d-flex justify-center pa-lg-8">
            <v-card width="1100" flat color="transparent">
                <!-- <v-card class="px-8 py-5 my-6 rounded-lg" flat>
                    <h2 class="pb-3">Reservas programadas</h2>
                    <v-card class="py-1 d-flex justify-center" flat>
                        <v-card-text class="py-2 text-center">
                            No hay reservas próximamente
                        </v-card-text>
                    </v-card>
                </v-card> -->
                <v-card class="py-5 my-6 rounded-lg" flat>
                    <div :class="$vuetify.breakpoint.mdAndDown ? 'px-6' : 'px-8'">
                        <h2 class="pb-3">Eventos recientes</h2>
                        <v-card-actions class="d-flex justify-end">
                            <v-btn :small="$vuetify.breakpoint.mdAndDown" @click="setStartDate(7)" elevation="0" class="mx-0 rounded-r-0 rounded-lg px-4" :class="{'teal accent-4 white--text': daysFromStart === 7}">Última semana</v-btn>
                            <v-btn :small="$vuetify.breakpoint.mdAndDown" @click="setStartDate(30)" elevation="0" class="mx-0 rounded-0 px-4" :class="{'teal accent-4 white--text': daysFromStart === 30, 'rounded-l-0 rounded-lg': $vuetify.breakpoint.mdAndDown }">Último mes</v-btn>
                            <v-btn v-if="!$vuetify.breakpoint.mdAndDown" @click="setStartDate(365)" elevation="0" class="mx-0 rounded-l-0 rounded-lg px-4" :class="{'teal accent-4 white--text': daysFromStart === 365}">Último año</v-btn>
                        </v-card-actions>
                    </div>
                    <!-- <v-card v-for="item in recentActivity" :key="item.id" class="py-1 d-flex flex-row" flat>
                        <v-avatar size="65" color="blue rounded-lg white--text text-h5" class="mr-1" tile>{{ item.customerInitials }}</v-avatar>
                        <v-card-text class="py-2">
                            <p class="pa-0 ma-0">
                                <span class="ml-2 font-weight-regular teal--text text--accent-4 body-1">{{ item.customerName }}</span>
                                <span class="ml-2 font-weight-regular body-1">cita el día <b>{{ item.bookingDate }}</b>, de <b>13:15 a 14:00</b></span>
                            </p>
                            <p class="pa-0 ma-0">
                                <span class="ml-2 grey--text body-2">Fecha de registro: {{ item.createdAt }}</span>
                            </p>
                        </v-card-text>
                    </v-card> -->
                    <v-card class="py-1 d-flex justify-center" flat v-if="(seriesData || [])[0]?.data?.length === 0">
                        <v-card-text class="py-2 text-center">
                            No hay actividad reciente
                        </v-card-text>
                    </v-card>
                    <v-card class="py-1" :class="{ 'px-0': !$vuetify.breakpoint.mdAndDown }" flat v-else>
                        <ChartComponent
                        :seriesData="seriesData"
                        dateFormat="yyyy-MM-dd"
                        chartType="bar"
                        height="400"
                        :showAxis="true"
                        />
                    </v-card>
                </v-card>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ChartComponent from '@/components/common/ChartComponent.vue';
import { formatDatetime } from '@/utils'

export default {
    name: 'ActivityView',
    components: {
        ChartComponent
    },
    data() {
        return {
            daysFromStart: 7,
            endDate: formatDatetime(new Date())
        }
    },
    computed: {
        ...mapGetters(['activity']),
        startDate() {
            return new Date((new Date()).setDate((new Date()).getDate() - this.daysFromStart));
        },
        seriesData() {
            return [
                {
                    name: 'Citas de clientes',
                    data: this.activity?.appointments,
                    unit: 'citas'
                },
                {
                    name: 'Ingresos',
                    data: this.activity?.revenue,
                    unit: '€',
                    type: 'line'
                }
            ]
        }
    },
    async created() {
        if (!this.activity?.appointments) {
            await this.fetchActivity({ startDate: this.startDate, endDate: this.endDate });
            console.log(this.activity);
        }
    },
    methods: {
        ...mapActions(['fetchActivity']),
        setStartDate(daysBefore) {
            this.daysFromStart = daysBefore;
            this.fetchActivity({ startDate: this.startDate, endDate: this.endDate });
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
