<template>
    <v-dialog
    :value="dialog"
    fullscreen
    transition="scroll-x-reverse-transition"
    >
        <v-card>
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="10" md="6">
                    <v-card class="elevation-0 pb-16">
                        <v-toolbar
                            class="elevation-0"
                            hide-on-scroll
                            >
                            <v-btn
                                icon
                                @click="back()"
                                large
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="ml-4 my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Términos y condiciones</v-card-title>
                        <v-divider class="mx-4"></v-divider>

                        <v-card class="elevation-0 mx-4" v-for="item in terms_and_conditions" :key="'tac_'+item.id">
                            <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3>{{ item.id }}.- {{ item.title }}</h3></v-card-title>
                            <v-divider v-if="item.title" class="mx-4"></v-divider>
                            <v-card-text class="text-break" v-for="text in item.content" :key="text" style="font-size: 18px;" v-html="text">
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'TermsAndConditionsComponent',
  props: ['dialog'],
  data () {
    return {
        terms_and_conditions: [           
            {
                id: 0,
                content: [
                    'Al acceder a nuestro sitio web, slotspot.es, usted acepta los términos y condiciones de servicio establecidos a continuación. Lea detenidamente estos términos antes de utilizar nuestros servicios.',
                ]
            },
            
            {
                id: 1,
                title: 'Definiciones',
                content: [
                    'En estos términos y condiciones, los siguientes términos tendrán los siguientes significados:',
                    '<b>Página web</b>: se refiere a la página de reservas de citas para negocios en slotspot.es.',
                    '<b>Usuario</b>: se refiere a cualquier usuario registrado en slotspot.es.',
                    '<b>Cita</b>: se refiere a la reserva de un servicio en un negocio específico.',
                    '<b>Reserva</b>: Acuerdo para asignar un lugar o un servicio, de un modo exclusivo, para uso y disfrute de una persona o grupo de personas determinado.'
                ]
            },
            {
                id: 2,
                title: 'Servicios',
                content: [
                    'BidApp VIP S.L., a través de slotspot.es, proporciona un servicio de reservas de citas para negocios. ',
                    'BidApp VIP S.L. no es responsable de cualquier problema o disputa que pueda surgir entre el usuario y el negocio.'
                ]
            },
            {
                id: 3,
                title: 'Registro',
                content: [
                    'Para utilizar los servicios de slotspot.es, los usuarios deben registrarse y crear una cuenta. La información de la cuenta debe ser precisa y completa. Los usuarios son responsables de mantener la seguridad de su cuenta y contraseña.'
                ]
            },
            {
                id: 4,
                title: 'Reservas',
                content: [
                    'Las reservas están abiertas a todos los usuarios registrados. Las citas deben ser reservadas para horarios específicos disponibles en cada negocio.'
                ]
            },
            {
                id: 5,
                title: 'Terminación y Suspensión',
                content: [
                    '<b>Terminación.</b> Usted es libre de dejar de utilizar nuestros Servicios en cualquier momento. Nos reservamos el derecho de suspender o cancelar su acceso a nuestros Servicios o eliminar su cuenta si determinamos:',

                    `<ul>
                        <li>Usted violó estos Términos o nuestras Políticas de uso.</li>
                        <li>Debemos hacerlo para cumplir con la ley</li>
                        <li>El uso que usted haga de nuestros Servicios podría causar riesgos o daños a Bidapp VIP SL, a nuestros usuarios o a cualquier otra persona.</li></ul>`,
                    'También podemos cancelar su cuenta si ha estado inactiva durante más de un año y no tiene una cuenta paga. Si lo hacemos, le avisaremos con antelación.',

                    '<b>Apelaciones.</b> Si cree que hemos suspendido o cancelado su cuenta por error, puede presentar una apelación comunicándose con nuestro equipo de soporte.',          ]
            },
            {
                id: 6,
                title: 'Cuentas pagadas',
                content: [
                    "<b>Facturación.</b> Si compra algún Servicio, proporcionará información de facturación completa y precisa, incluido un método de pago válido. Para las suscripciones pagas, cargaremos automáticamente su método de pago en cada renovación periódica acordada hasta que cancele. Usted es responsable de todos los impuestos aplicables y le cobraremos impuestos cuando sea necesario. Si no se puede completar su pago, podemos reducir su cuenta o suspender su acceso a nuestros Servicios hasta que se reciba el pago.",

                    "<b>Créditos de servicio.</b> Puede pagar algunos Servicios por adelantado comprando créditos de servicio. Todos los créditos de servicio están sujetos a nuestros Términos de crédito de servicio.",

                    "<b>Cancelación.</b> Puede cancelar su suscripción paga en cualquier momento. Los pagos no son reembolsables, excepto cuando lo exija la ley. Estos Términos no anulan ninguna ley local obligatoria con respecto a sus derechos de cancelación.",

                    "<b>Cambios.</b> Podemos cambiar nuestros precios de vez en cuando. Si aumentamos los precios de nuestra suscripción, le avisaremos con al menos 30 días de antelación y cualquier aumento de precio entrará en vigor en su próxima renovación para que pueda cancelar si no está de acuerdo con el aumento de precio.",
                ],
            },
            {
                id: 7,
                title: 'Propiedad intelectual',
                content: ['Todo el contenido de slotspot.es, incluyendo texto, gráficos, logotipos, imágenes y software, es propiedad de BidApp VIP S.L. o de sus proveedores de contenido. Los usuarios no pueden copiar, reproducir, distribuir o utilizar de cualquier otro modo el contenido sin el consentimiento previo y por escrito de BidApp VIP S.L.']
            },
            {
                id: 8,
                title: 'Limitación de responsabilidad',
                content: ['Slotspot.es y BidApp VIP S.L. no serán responsables de ningún daño, pérdida o lesión derivados del uso de los servicios de la página web. Slotspot.es no garantiza la calidad, seguridad o idoneidad de los servicios ofrecidos en las reservas.']
            },
            {
                id: 9,
                title: 'Modificaciones',
                content: ['Slotspot.es se reserva el derecho de modificar estos términos y condiciones en cualquier momento. Los usuarios serán notificados de las modificaciones y se les solicitará que acepten los nuevos términos antes de continuar utilizando los servicios de la página web.']
            },
            {
                id: 10,
                title: 'Ley aplicable y jurisdicción',
                content: ['Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de España. Cualquier disputa que surja en relación con estos términos y condiciones se resolverá exclusivamente en los tribunales de España.']
            },
            {
                id: 11,
                content: ['Al utilizar los servicios de slotspot.es, los usuarios aceptan los términos y condiciones de servicio establecidos en este documento.']
            }
        ],
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /* .v-dialog > .v-card > .v-toolbar {
        position: sticky;
        top: 0;
        z-index: 999;
    } */

</style>
