<template>
    <div>
        <v-navigation-drawer app class="translucient-white pt-2" left clipped width="300">
            <v-list nav :value="selectedItem">
                <v-list-item-group v-model="selectedItem" color="teal accent-4" mandatory>
                    <template>
                        <v-list-item
                        v-for="item in navigationItems"
                        :key="item.title"
                        link
                        @click="$router.push(item.path)"
                        >
                            <template>
                                <v-list-item-icon class="mx-2">
                                    <v-icon left>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="font-weight-bold body-2">{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
            <v-divider class="mt-3"></v-divider>
        </v-navigation-drawer>
        <v-main app>
            <router-view/>
        </v-main>
    </div>
</template>

<script>

export default {
    name: 'MarketingLayout',
    components: {

    },
    data() {
        return {
            
        }
    },
    computed: {
        selectedItem() {
            return this.navigationItems.findIndex(item => item.path === this.$route.path)
        },
        navigationItems () {
            const businessId = localStorage.getItem('businessId')
            return [
                {
                    icon: 'mdi-link-variant',
                    title: 'Enlaces',
                    path: `/businesses/${businessId}/marketing/links`,
                },
                {
                    icon: 'mdi-security',
                    title: 'Políticas',
                    path: `/businesses/${businessId}/marketing/policies`,
                },
                // {
                //     icon: 'mdi-pencil-ruler',
                //     title: 'Diseño',
                //     path: `/businesses/${businessId}/marketing/design`,
                // },
                // {
                //     icon: 'mdi-cog',
                //     title: 'Configuración',
                //     path: `/businesses/${businessId}/marketing/settings`,
                // },
            ]
        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
