<template>
    <div class="pa-2 pa-lg-0">
        <v-toolbar flat color="transparent">
            <h2 class="ml-n5 pl-0">
                Clientes
                <v-chip outlined label class="ml-4 mt-n1 rounded-lg" color="grey darken-1">{{ clients.length }}</v-chip>
            </h2>
            <v-spacer></v-spacer>
            <v-btn elevation="0" dark color="teal accent-4" class="mr-n6 mt-2 px-6 py-5 rounded-lg text-none" @click="$router.push('clients/form/new')">
                <v-icon left>mdi-account-plus</v-icon>
                Añadir cliente
            </v-btn>
        </v-toolbar>
        <v-row class="pt-lg-2 pt-4">
            <v-col cols="12" lg="6" v-for="client in clients" :key="client.id" class="pa-1">
                <v-card class="px-1 pt-1 d-flex flex-row" flat outlined @click="$router.push(`clients/details/${client.id}`)">
                    <v-avatar size="65" :color="client.color" style="opacity: 0.7" class="ma-2 rounded-lg white--text text-h5" tile>{{ client.initials }}</v-avatar>
                    <v-card-text class="pl-0 pt-1 pb-0">
                        <p class="pa-0 ma-0 mt-1">
                            <span class="ml-2 font-weight-bold subtitle-2">{{ client.fullName }}</span>
                        </p>
                        <p class="pa-0 ma-0 mt-n1">
                            <span class="ml-2 font-weight-light subtitle-2">{{ client.email }}</span>
                        </p>
                        <p class="pa-0 mt-n1">
                            <span class="ml-2 font-weight-light subtitle-2">{{ client.phoneNumber }}</span>
                        </p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ListView',
    components: {
    },
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters(['clients'])
    },
    created() {
        this.fetchClients()
    },
    methods: {
        ...mapActions(['fetchClients'])
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
