<template>
    <div :id="chartId"></div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { formatDatetime, truncateDecimals } from '@/utils'

export default {
    props: {
        seriesData: {
            type: Array,
            required: true
        },
        dateFormat: {
            type: String,
            default: 'yyyy-MM-dd'
        },
        chartType: {
            type: String,
            default: 'area'
        },
        height: {
            type: String,
            default: 'auto'
        },
        colors: {
            type: Array,
            default: () => ['#00BFA5', '#008FFB', '#0F766E', '#FF4560', '#00E396', '#FFD666']
        },
        showAxis: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            chartId: `chart-${Math.random().toString(36).substr(2, 9)}`,
            chart: null
        }
    },
    computed: {
        chartOptions() {
            if (!this.seriesData || (this.seriesData || [])[0]?.data?.length === 0) return null
            const defaultChartType = this.chartType

            // Obtener todas las unidades y verificar si hay más de una distinta
            const uniqueUnits = [...new Set(this.seriesData.map(series => series.unit))]

            return {
                chart: {
                    type: defaultChartType,
                    toolbar: {
                        show: false,
                    },
                    height: this.height,
                },
                dataLabels: {
                    enabled: false
                },
                series: this.seriesData.map((series) => ({
                    name: series.name || 'Unknown',
                    type: series.type || defaultChartType,
                    data: series.data.map(data => ({
                        x: new Date(data.timestamp).getTime(),
                        y: truncateDecimals(data.value)
                    })),
                    yAxis: uniqueUnits.indexOf(series.unit)  // Asigna el eje Y basado en la unidad
                })),
                colors: this.colors,
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: this.dateFormat,
                        show: this.showAxis,
                    },
                    axisBorder: {
                        show: this.showAxis,
                    },
                    axisTicks: {
                        show: true,
                    },
                    tooltip: {
                        enabled: this.showAxis,
                    },
                },
                yaxis: uniqueUnits.map((unit, index) => ({
                    seriesName: unit,
                    opposite: index % 2 === 1,  // Alternar el eje a la derecha
                    labels: {
                        show: this.showAxis,
                        formatter: (val) => parseInt(Math.round(val))  // Asegurar que solo se muestren enteros
                    },
                    title: {
                        text: unit
                    },
                    tickAmount: Math.max(...this.seriesData.filter(series => series.unit === unit).flatMap(series => series.data.map(data => data.value))) > 10 ? 10 : Math.max(...this.seriesData.filter(series => series.unit === unit).flatMap(series => series.data.map(data => data.value))),
                    min: Math.min(...this.seriesData.filter(series => series.unit === unit).flatMap(series => series.data.map(data => data.value))) > 0 ? 0 : Math.min(...this.seriesData.filter(series => series.unit === unit).flatMap(series => series.data.map(data => data.value))),
                    max: Math.max(...this.seriesData.filter(series => series.unit === unit).flatMap(series => series.data.map(data => data.value)))
                })),
                grid: {
                    show: false,
                    padding: {
                        left: 0,
                        right: this.showAxis ? 10 : 0, // To avoid the last point to be cut off
                    },
                },
                fill: {
                    type: defaultChartType === 'area' ? 'gradient' : 'solid',
                    gradient: {
                        shadeIntensity: 1,
                        opacityFrom: 0.7,
                        opacityTo: 0.9,
                        stops: [0, 90, 100]
                    }
                },
                markers: {
                    size: this.seriesData[0].data.length > 100 ? 0 : 6,
                    colors: ['#FFFFFF'],
                    strokeColors: this.colors,
                    strokeWidth: 2,
                    strokeOpacity: 0.9,
                    strokeDashArray: 0,
                    fillOpacity: 1,
                    discrete: [],
                    shape: "circle",
                    radius: 2,
                    offsetX: 0,
                    offsetY: 0,
                    onClick: undefined,
                    onDblClick: undefined,
                    showNullDataPoints: true,
                    hover: {
                        size: undefined,
                        sizeOffset: 3
                    }
                },
                stroke: {
                    show: true,
                    curve: 'straight',
                    lineCap: 'butt',
                    colors: undefined,
                    width: 2,
                    dashArray: 0,
                },
                tooltip: {
                    x: {
                        show: true,
                        format: this.dateFormat,
                    },
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const dataSeries = [...series].map((s, idx) => ({ ...s, idx }))
                        dataSeries.sort((a, b) => b[dataPointIndex] - a[dataPointIndex])
                        const dataPoints = dataSeries.map((serie, idx, series) => {
                            const chip = this.seriesData[serie.idx].name ?
                                `<div class="tooltip-chip" style="background-color: ${this.colors[serie.idx]};">${this.seriesData[serie.idx].name}</div>`
                                :
                                `<div class="tooltip-chip" style="background-color: ${this.colors[serie.idx]};"></div>`
                            if (typeof series[idx][dataPointIndex] !== 'number') return ''
                            return (
                                `<div class="tooltip-data">
                                    <span>
                                    <strong class="tooltip-value">
                                        ${series[idx][dataPointIndex]} <span class="tooltip-unit">${this.seriesData[serie.idx].unit || ''}</span>
                                    </strong>
                                    </span>
                                    ${chip}
                                </div>`
                            )
                        })
                        return (
                            `<div class="tooltip-container">
                            <div class="tooltip-content">
                                <span class="tooltip-date">
                                ${formatDatetime(new Date(w.globals.seriesX[seriesIndex][dataPointIndex]), this.dateFormat)}
                                </span>
                                ${dataPoints.join('')}
                            </div>
                            </div>`
                        )
                    }
                }
            }
        },
    },
    methods: {
        loadChart() {
            if (this.chartOptions === null) return
            const chartElement = document.getElementById(this.chartId)
            if (chartElement) chartElement.innerHTML = ''
            this.chart = new ApexCharts(chartElement, this.chartOptions)
            this.chart.render()
        }
    },
    mounted() {
        this.loadChart()
    },
    watch: {
        seriesData: {
            handler() {
                this.loadChart()
            },
            deep: true
        },
        dateFormat() {
            this.loadChart()
        },
        chartType() {
            this.loadChart()
        },
        height() {
            this.loadChart()
        },
        colors() {
            this.loadChart()
        },
        showAxis() {
            this.loadChart()
        }
    }
}
</script>

<style>
#chartId {
  width: 100%;
  height: 100%;
}

.tooltip-container {
  padding: 8px;
}
.tooltip-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.tooltip-date {
  display: inline-block;
  padding: 2px 8px;
  font-family: monospace;
  font-size: 12px;
  color: #4a4a4a;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: rgba(189, 189, 189, 0.5);
}
.tooltip-data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  gap: 20px;
}
.tooltip-value {
  font-size: 18px;
  font-weight: bold;
}
.tooltip-unit {
  font-size: 14px;
  font-weight: normal;
}
.tooltip-chip {
  display: inline-block;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 10px;
  color: rgba(255, 255, 255, 0.8);
}
</style>
