<template>
    <v-dialog v-model="imageDialog" width="950px">
        <v-card>
            <v-card-title>
                <span class="headline">Resultado del recorte</span>
            </v-card-title>
            <v-card-text class="px-0" style="max-height: 70dvh; overflow-y: auto;">
                <Cropper ref="cropper" class="cropper" :src="imageUrl" :stencil-props="{
                    aspectRatio: 16 / 9
                }" :boundaries="visibleArea" @change="change" :coordinates="coordinates" @ready="setInitCoordinates"/>
            </v-card-text>
            <v-card-actions class="py-6">
                <v-spacer></v-spacer>
                <v-btn color="grey lighten-1" class="mr-3 pa-5 px-6 font-weight-black d-md-flex elevation-0"
                    @click="imageDialog = false" outlined><span class="grey--text text--darken-1">Cancelar</span></v-btn>
                <v-btn color="teal accent-4" class="mr-3 pa-5 px-6 font-weight-black d-md-flex elevation-0"
                    @click="saveCroppedImage" dark>Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper';
import { mapActions } from 'vuex';

export default {
    name: 'CropperDialog',
    components: {
        Cropper
    },
    props: {
        value: {
            Boolean, 
            default: false
        },
        imageUrl: {
            String,
            default: '/booking-bg.webp'
        },
        originalImageFile: {
            Object,
            default: null
        }
    },
    data() {
        return {
            coordinates: {
                left: 0,
                top: 0,
                width: 0,
                height: 0
            },
            visibleArea: {
                left: 0,
                top: 0,
                width: 950,
                height: 500,
            },
            aspectRatio: 1,
            canvas: null,
        }
    },
    computed: {
        imageDialog: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
    },
    mounted() {
        if (this.imageUrl.indexOf('?')) {
            const searchParams = new URLSearchParams(this.imageUrl.split('?')[1]);
            this.aspectRatio = searchParams.get('aspectRatio') || 1;
            this.coordinates = {
                left: parseInt(searchParams.get('left')) || 0,
                top: parseInt(searchParams.get('top')) || 0,
                width: parseInt(searchParams.get('width')) || 300,
                height: parseInt(searchParams.get('height')) || 300,
            };
            // this.$refs.cropper.setCoordinates(this.coordinates);
            // console.log(this.coordinates);
        }
    },
    methods: {
        ...mapActions(['uploadBookingBgImage']),
        setInitCoordinates() {
            this.$refs.cropper.setCoordinates(this.coordinates);
            console.log(this.originalImageFile);
        },
        change(e) {
            this.coordinates = e.coordinates;
            this.canvas = e.canvas
        },
        async saveCroppedImage() {
            const canvas = this.$refs.cropper.getResult().canvas;

            if (canvas) {
                const blob = await new Promise(resolve => canvas.toBlob(resolve));
                console.log(blob);
                this.uploadImage(blob);
            }
        },
        uploadImage(blob) {
            // Append original image to form data and cropped image to form data


            this.uploadBookingBgImage({ blob, coordinates: this.coordinates, originalImageFile: this.originalImageFile });

            this.imageDialog = false;
        }
    },
}
</script>
<style scoped>
.cropper {
    width: 950px;
    background: #DDD;
    overflow: hidden;
}
</style>
