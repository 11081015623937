<template>
    <v-app id="app-menu">
        <AppBar @toggleDrawer="toggleDrawer"/>
        <MobileNavigationDrawer v-model="drawerClick"/>
        <router-view />
        <SnackBar/>
        <LoadingLogo />
    </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import AppBar from '@/components/business/AppBar.vue'
import MobileNavigationDrawer from '@/components/business/MobileNavigationDrawer.vue'
import SnackBar from '@/components/common/SnackBar.vue'
import LoadingLogo from '@/components/common/LoadingLogo.vue'

export default {
    name: 'MainLayout',
    components: { 
        AppBar, 
        MobileNavigationDrawer, 
        SnackBar, 
        LoadingLogo 
    },
    props: {
        businessId:  {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            drawerClick: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        toggleDrawer () {
            this.drawerClick = !this.drawerClick
        },
    },
    created () {
        if (!localStorage.getItem('token')) { // Redirect to home
            this.$router.push('/')
        }
        if (this.businessId !== localStorage.getItem('businessId')) { // Refresh
            localStorage.setItem('businessId', this.businessId)
            location.reload()
        }
    }
}
</script>

<style scoped>
    #app-menu {
        background: #ebebeb;
    }
</style>
