<!-- TODO: No añadir botón de añadir categoría -->
<template>     
    <div>
        <v-toolbar flat height="80" class="px-lg-4">
            <v-btn @click="$router.back()" icon><v-icon dense class="mt-1">mdi-chevron-left</v-icon></v-btn>
            <h2 :style="$vuetify.breakpoint.mdAndDown && 'font-size: 20px;'" class="text-wrap">
                {{ serviceId !== 'new' ? service.name : 'Añadir servicio' }}
            </h2>
            <v-spacer></v-spacer>
            <v-btn
            color="primary"
            class="py-5 px-6 rounded-lg"
            small
            :disabled="!hasChanged"
            @click="save"
            >
            <v-icon left>mdi-content-save</v-icon>
            Guardar
            </v-btn>

        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Detalles del servicio</h4>
                    </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6">
                    <h4 class="black--text">Nombre</h4>
                    <v-text-field v-model="service.name" label="Nombre del servicio" outlined solo flat dense class="mt-2"></v-text-field>
                    <h4 class="black--text">Color</h4>
                    <v-autocomplete v-model="service.color" :items="colors" item-text="text" item-value="value" label="Color" solo flat outlined dense class="mt-2">
                        <template v-slot:selection="{ item }">
                            <v-chip :color="item.value" small label class="rounded-lg mr-3">
                            </v-chip>
                            <span>{{ item.text }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <v-chip :color="item.value" small label class="rounded-lg mr-3">
                            </v-chip>
                            <span class="font-weight-regular">{{ item.text }}</span>
                        </template>
                    </v-autocomplete>
                    <h4 class="black--text">Descripción</h4>
                    <!-- TODO: Modify persisten hint -->
                    <v-textarea v-model="service.description" label="Describe brevemente el servicio" outlined dense class="mt-2"></v-textarea>
                    <h4 class="black--text">Duración</h4>
                    <div class="d-flex flex-row" style="max-width: 300px;">
                        <v-text-field v-model="serviceDurationHours" suffix="horas" outlined dense class="mt-2"></v-text-field>
                        <v-text-field v-model="serviceDurationMinutes" suffix="minutos" outlined dense class="mt-2 ml-2"></v-text-field>
                    </div>
                    <h4 class="black--text">Precio</h4>
                    <div class="d-flex flex-row" style="max-width: 300px;">
                        <v-text-field v-model="service.price" suffix="€" outlined dense class="mt-2"></v-text-field>
                    </div>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Empleados</h4>
                    </v-card-title>
                <v-divider></v-divider>
                <!-- <v-card-text class="py-3 px-6 d-flex flex-row justify-center">
                    <span class="pt-1">No hay empleados asociados a este servicio.</span>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text class="d-flex self-align-end font-weight-bold pr-0" @click="addressDialog=true">Añadir empleado</v-btn>
                </v-card-text> -->
                <v-card-text class="py-3 px-6 d-flex flex-row justify-center">
                    <v-autocomplete v-model="service.employees" :items="employees" item-text="fullName" item-value="id" label="Empleados asociados" solo flat outlined dense class="mt-2" multiple return-object>
                        <template v-slot:selection="{ item }">
                            <v-chip :color="`${item.color?.split(' ')[0]} lighten-3`" small label class="rounded-lg mr-1">
                                {{ item.fullName }}
                            </v-chip>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item @click="allEmployees=!allEmployees">
                                <v-list-item-action>
                                    <v-checkbox v-model="allEmployees" readonly color="primary"></v-checkbox> 
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Todos los empleados</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <!-- <template v-slot:item="{ item }">
                            <v-chip :color="item.value" small label class="rounded-lg mr-3">
                            </v-chip>
                            <span class="font-weight-regular">{{ item.text }}</span>
                        </template> -->
                    </v-autocomplete>
                    <!-- <v-spacer></v-spacer>
                    <v-btn color="primary" text class="d-flex self-align-end font-weight-bold pr-0" @click="addressDialog=true">Añadir empleado</v-btn> -->
                </v-card-text>
            </v-card>
            <v-row v-if="this.serviceId !== 'new'">
                <v-col cols="12" class="d-flex justify-end">
                    <v-btn color="red" class="py-5 px-6 rounded-lg text-none" small @click="handleClickDelete" plain>
                        <v-icon left>mdi-trash-can-outline</v-icon>
                        Eliminar servicio
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog
        v-model="confirmDeleteDialog"
        max-width="350"
        >
            <v-card class="py-2 px-1">
                <v-card-title><h4>Eliminar servicio</h4></v-card-title>
                <v-card-text>¿Estás seguro de que quieres eliminar este servicio?</v-card-text>
                <v-card-actions class="pb-3">
                    <v-spacer></v-spacer>
                    <v-btn
                        plain
                        @click="confirmDeleteDialog = false"
                        small
                        class="px-4 py-4 plain-text"
                    >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="red darken-1"
                        @click="remove"
                        small
                        class="px-4 py-4 white--text plain-text"
                    >
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <!-- TODO: Poder añadir clientes simultáneos -->
    <!-- TODO: Ver si se puede añadir un margen de tiempo entre tareas -->
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
    name: 'ServicesFormView',
    props: {
        serviceId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            hasChanged: false,
            service: {
                duration: 0,
                employees: [],
            },
            defaultService: '',
            confirmDeleteDialog: false
        }
    },
    computed: {
        ...mapGetters(['services', 'employees', 'colors', 'getServiceDuration']),
        ...mapMutations(['setServiceDuration']),
        allEmployees: {
            get() {
                return this.service.employees.length === this.employees.length
            },
            set(value) {
                if (value) {
                    this.service.employees = Object.assign([], this.employees)
                } else {
                    this.service.employees = []
                }
            }
        },
        serializedService() {
            return JSON.stringify(this.service)
        },
        serviceDurationHours: {
            get() {
                console.log('min-get', this.service.duration)
                return Math.floor(this.service.duration / 3600)
            },
            set(value) {
                console.log('min-set', value)
                const duration = this.serviceDurationMinutes * 60 + value * 3600
                this.service.duration = duration >= 0 ? duration : 0
            }
        },
        serviceDurationMinutes: {
            get() {
                console.log('hour-get', this.service.duration)
                return Math.floor((this.service.duration % 3600) / 60)
            },
            set(value) {
                console.log('hour-get', value)
                const duration = this.serviceDurationHours * 3600 + value * 60
                this.service.duration = duration >= 0 ? duration : 0
            }
        }
    },
    watch: {
        serializedService() {
            this.hasChanged = this.serializedService !== this.defaultService && this.defaultService !== ''
        }
    },
    async created() {
        if (this.employees.length === 0) this.fetchEmployees()

        if (this.serviceId === 'new') {
            this.defaultService = JSON.stringify(this.service)
        } else {
            this.service = await this.fetchService(this.serviceId)
            this.$nextTick(() => {
                this.defaultService = JSON.stringify(this.service)
            })
        }
    },
    methods: {
        ...mapActions(['fetchEmployees', 'fetchService', 'updateService', 'createService', 'deleteService']),
        ...mapMutations(['setServices']),
        handleClickDelete() {
            this.confirmDeleteDialog = true
        },
        async save() {
            if (this.serviceId === 'new') {
                await this.createService(this.service)
            } else {
                await this.updateService(this.service)
            }
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/business/services`)
        },
        async remove() {
            await this.deleteService(this.serviceId)
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/business/services`)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
