<template>     
    <div>
        <v-toolbar flat height="80" class="px-lg-4">
            <h2>
                Ajustes de reservas
            </h2>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card outlined class="rounded-lg mb-4">
                <v-card-title class="pl-5 text-h6 font-weight-bold">
                    Reservas online
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="px-5 black--text">
                    <div class="d-flex flex-column">
                        <span class="text-h6">Límites de tiempo de las reservas</span>
                        <span>Establecer con cuánta antelación los clientes pueden reservar un servicio</span>
                    </div>
                    <v-row class="mt-3">
                        <v-col cols="12" lg="4" :class="$vuetify.breakpoint.mdAndDown && 'pt-0'">
                            <span class="font-weight-bold">No antes de</span>
                            <v-autocomplete
                                :items="timingValues.maxTimeLimitBeforeValues"
                                item-text="label"
                                item-value="value"
                                v-model="bookingRule.maxTimeLimitBefore"
                                class="mt-1"
                                outlined
                                dense
                                solo
                                flat
                            ></v-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="4" :class="$vuetify.breakpoint.mdAndDown && 'pb-0'">
                            <span class="font-weight-bold">No más tarde de</span>
                            <v-autocomplete
                                :items="timingValues.minTimeLimitBeforeValues"
                                item-text="label"
                                item-value="value"
                                v-model="bookingRule.minTimeLimitBefore"
                                class="mt-1"
                                outlined
                                dense
                                solo
                                flat
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-divider class="mb-4"></v-divider>
                    <div class="d-flex flex-column">
                        <span class="text-h6">Frecuencia de reservas</span>
                        <span>Define la periodicidad con la que se ofrecerán los espacios para programar citas</span>
                    </div>
                    <v-row class="mt-3">
                        <v-col cols="12" lg="4">
                            <span class="font-weight-bold">Cada</span>
                            <v-autocomplete
                                :items="timingValues.timeSlotFrequencyValues"
                                item-text="label"
                                item-value="value"
                                v-model="bookingRule.timeSlotFrequency"
                                class="mt-1"
                                outlined
                                dense
                                solo
                                flat
                            ></v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-divider class="mb-4"></v-divider>
                    <div class="d-flex flex-row">
                        <div class="d-flex flex-column">
                            <span class="text-h6">Aprobación manual de reservas</span>
                            <span>Deseo revisar y confirmar manualmente cada reserva online</span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-switch v-model="bookingRule.manualApproval" color="teal accent-4" inset class="mr-3"></v-switch>
                    </div>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-4">
                <v-card-text class="px-5 black--text">
                    <div class="d-flex flex-column">
                        <span class="text-h6">Cancelación</span>
                        <span>Establecer con cuánta antelación los clientes pueden cancelar un servicio</span>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="px-5 black--text">
                    <v-row>
                        <v-col cols="12" lg="4">
                            <v-autocomplete
                                :items="timingValues.cancellationNoticePeriodValues"
                                item-text="label"
                                item-value="value"
                                v-model="bookingRule.cancellationNoticePeriod"
                                class="mt-1"
                                outlined
                                dense
                                solo
                                flat
                            ></v-autocomplete>
                        </v-col>
                    </v-row>         
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-4">
                <v-card-text class="px-5 black--text">
                    <div class="d-flex flex-column">
                        <span class="text-h6">Recordatorios</span>
                        <span>Establecer con qué anticipación antes de una cita se envía un mensaje de recordatorio al cliente</span>
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-text class="px-5 black--text">
                    <v-row>
                        <v-col cols="12" lg="4">
                            <v-autocomplete
                                :items="timingValues.reminderTimeBeforeValues"
                                item-text="label"
                                item-value="value"
                                v-model="bookingRule.reminderTimeBefore"
                                class="mt-1"
                                outlined
                                dense
                                solo
                                flat
                            ></v-autocomplete>
                        </v-col>
                    </v-row>         
                </v-card-text>
            </v-card>
            <div class="d-flex flex-row justify-end pt-1 pb-5 py-lg-1">
                <v-btn text :disabled="!hasChanged">Descartar</v-btn>
                <v-btn
                color="primary"
                class="py-5 px-6 rounded-lg"
                small
                :disabled="!hasChanged"
                @click="save"
                >
                <v-icon left>mdi-content-save</v-icon>
                Guardar
                </v-btn>
            </div>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'BookingRulesView',
    components: {
    },
    data() {
        return {
            hasChanged: false,
            bookingRule: {},
            defaultBookingRule: "",
        }
    },
    computed: {
        ...mapGetters(['timingValues']),
        serializedBookingRule() {
            return JSON.stringify(this.bookingRule)
        }
    },
    watch: {
        serializedBookingRule() {
            this.hasChanged = this.serializedBookingRule !== this.defaultBookingRule && this.defaultBookingRule !== ''
        }
    },
    async created() {
        // TODO: Check if it is necessary to fetch the timing values here
        this.fetchTimingValues()
        this.bookingRule = await this.fetchBookingRule()
        this.$nextTick(() => {
            this.defaultBookingRule = JSON.stringify(this.bookingRule)
        })
    },
    methods: {
        ...mapActions(['fetchTimingValues', 'fetchBookingRule', 'updateBookingRule']),
        async save() {
            await this.updateBookingRule(this.bookingRule)
            this.hasChanged = false
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
