<template>
    <v-row class="d-flex justify-center">
        <v-col cols="12" sm ="10" md="8">
            <v-container class="ma-0 pa-0">
                <v-card class="elevation-0">
                    <v-card-title class="mt-16 font-weight-black"><h3>¿Cómo podemos ayudarte?</h3></v-card-title>
                    <v-divider class="mx-4"></v-divider>

                    <v-list>
                        <!-- <v-subheader>REPORTS</v-subheader> -->
                        <v-list-item-group
                            color="primary"
                            class="mx-2"
                        >
                            <v-list-item 
                            class="py-3" 
                            v-for="item in help_items" 
                            :key="item.title"
                            @click="item.action()"
                            >
                                <v-list-item-avatar>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon>mdi-chevron-right</v-icon>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
                <ContactComponent :dialog="contact_dialog" @update:dialog="(val) => contact_dialog = val"/>
                <FrequentQuestionsComponent :dialog="frequent_questions_dialog" @update:dialog="(val) => frequent_questions_dialog = val"/>
                <TermsAndConditionsComponent :dialog="terms_and_conditions_dialog" @update:dialog="(val) => terms_and_conditions_dialog = val"/>
                <CookiesPolicyComponent :dialog="cookies_policy_dialog" @update:dialog="(val) => cookies_policy_dialog = val"/>
                <PrivacyPolicyComponent :dialog="privacy_policy_dialog" @update:dialog="(val) => privacy_policy_dialog = val"/>
            </v-container>
        </v-col>
    </v-row>
</template>

<script>
import ContactComponent from '@/components/public/Help/ContactComponent.vue'
import FrequentQuestionsComponent from '@/components/public/Help/FrequentQuestionsComponent.vue'
import TermsAndConditionsComponent from '@/components/public/Help/TermsAndConditionsComponent.vue'
import CookiesPolicyComponent from '@/components/public/Help/CookiesPolicyComponent.vue'
import PrivacyPolicyComponent from '@/components/public/Help/PrivacyPolicyComponent.vue'

export default {
  name: 'HelpView',
  components: {
    ContactComponent,
    FrequentQuestionsComponent,
    TermsAndConditionsComponent,
    CookiesPolicyComponent,
    PrivacyPolicyComponent,
  },
  props: {
    section: {
        type: String,
        required: false,
    }
  },
  data () {
    return {
        frequent_questions_dialog: false,
        terms_and_conditions_dialog: false,
        cookies_policy_dialog: false,
        privacy_policy_dialog: false,
        contact_dialog: false,
        help_items: [
            {
                name: 'contact',
                title: 'Contacto',
                icon: 'mdi-phone',
                action: () => {
                    this.contact_dialog = true
                }
            },
            {
                name: 'frequent_questions',
                title: 'Preguntas frecuentes',
                icon: 'mdi-help-circle',
                action: () => {
                    this.frequent_questions_dialog = true
                }
            },
            {
                name: 'terms_and_conditions',
                title: 'Términos y condiciones',
                icon: 'mdi-file-document',
                action: () => {
                    this.terms_and_conditions_dialog = true
                }
            },
            {
                name: 'privacy_policy',
                title: 'Política de privacidad',
                icon: 'mdi-file-document',
                action: () => {
                    this.privacy_policy_dialog = true
                }
            },
            {
                name: 'cookies_policy',
                title: 'Política de cookies',
                icon: 'mdi-file-document',
                action: () => {
                    this.cookies_policy_dialog = true
                }
            },
        ],
    }
  },
  created () {
    if (this.section) {
        this.help_items.find(item => item.name === this.section).action()
    }
  },
  methods: {

  }
}
</script>