<template>
    <div>
        <v-toolbar flat height="80" class="px-lg-4">
            <v-btn @click="$router.back()" icon><v-icon dense class="mt-1">mdi-chevron-left</v-icon></v-btn>
            <h2 :style="$vuetify.breakpoint.mdAndDown && 'font-size: 20px;'" class="text-wrap">
                {{ vacationId !== 'new' ? 'Editar ausencia' : 'Añadir ausencia' }}
            </h2>
            <v-spacer></v-spacer>
            <v-btn color="primary" class="py-5 px-6 rounded-lg" small :disabled="!hasChanged" @click="save">
                <v-icon left>mdi-content-save</v-icon>
                Guardar
            </v-btn>
        </v-toolbar>
        <v-container fluid class="pa-lg-8">
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Detalles de la ausencia</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6">
                    <h4 class="black--text">Nombre</h4>
                    <v-text-field v-model="vacation.name" label="Nombre de la ausencia" outlined solo flat dense class="mt-2"
                        @input="hasChanged = true"></v-text-field>
                    <div class="mb-2">
                        <v-btn class="pa-5 elevation-0 rounded-lg rounded-r-0 caption font-weight-bold" small
                            :color="vacation.isFullDay == true ? 'teal accent-4' : 'grey lighten-3'"
                            :dark="vacation.isFullDay == true" @click="vacation.isFullDay = true; hasChanged=true;">
                            Días completos
                        </v-btn>
                        <v-btn class="pa-5 elevation-0 rounded-lg rounded-l-0 caption font-weight-bold" small
                            :color="vacation.isFullDay == false ? 'teal accent-4' : 'grey lighten-3'"
                            :dark="vacation.isFullDay == false" @click="vacation.isFullDay = false; hasChanged=true;">
                            Ajustar por horas
                        </v-btn>
                    </div>
                    <div>
                        <v-row class="pb-3">
                            <v-col cols="12" lg="3">
                                <h4 class="black--text">De</h4>
                                <v-menu
                                ref="menuStartDate"
                                v-model="menuStartDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="startDate" 
                                        outlined 
                                        solo 
                                        flat 
                                        dense 
                                        class="mt-2"
                                        readonly
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startDate"
                                        locale="es"
                                        color="primary"
                                        @input="menuStartDate = false; hasChanged = true;"
                                        first-day-of-week="1"
                                    ></v-date-picker>
                                </v-menu>
                                <v-menu v-if="vacation.isFullDay == false" ref="menuStartTime" v-model="menuStartTime" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="pa-0 mt-1 timetable-input" v-model="startTime"
                                            append-icon="mdi-clock-outline" v-bind="attrs" v-on="on" hide-details outlined
                                            dense @change="hasChanged = true" readonly></v-text-field>
                                    </template>
                                    <v-time-picker v-if="menuStartTime" v-model="startTime" format="24hr" min="00:00"
                                        max="23:59" color="primary"></v-time-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" lg="3">
                                <h4 class="black--text">a</h4>
                                <v-menu
                                ref="menuEndDate"
                                v-model="menuEndDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        v-model="endDate" 
                                        outlined 
                                        solo 
                                        flat 
                                        dense 
                                        class="mt-2"
                                        readonly
                                        append-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        hide-details
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="endDate"
                                        locale="es"
                                        color="primary"
                                        @input="menuEndDate = false; hasChanged = true;"
                                        first-day-of-week="1"
                                    ></v-date-picker>
                                </v-menu>
                                <v-menu v-if="vacation.isFullDay == false" ref="menuEndTime" v-model="menuEndTime" :close-on-content-click="false" :nudge-right="40"
                                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field class="pa-0 mt-1 timetable-input" v-model="endTime"
                                            append-icon="mdi-clock-outline" v-bind="attrs" v-on="on" hide-details outlined
                                            dense @change="hasChanged = true" readonly></v-text-field>
                                    </template>
                                    <v-time-picker v-if="menuEndTime" v-model="endTime" format="24hr" min="00:00"
                                        max="23:59" color="primary"></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
            </v-card>
            <v-card outlined class="rounded-lg mb-5">
                <v-card-title class="py-3 px-6">
                    <h4 style="font-size: 18px;">Empleados</h4>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="py-3 px-6 d-flex flex-row justify-center">
                    <v-autocomplete v-if="employees.length>0 && vacation.employees" v-model="vacation.employees" :items="employees" item-text="fullName" label="Equipo" solo flat outlined dense multiple return-object prepend-icon="mdi-account-multiple" @change="hasChanged=true;">
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="!allEmployees" :color="`${item.color?.split(' ')[0]} lighten-3`" small label class="rounded-lg mr-1">
                                {{ item.fullName }}
                            </v-chip>
                            <span v-else-if="index==0" class="body-1 pl-2 pt-1 grey--text text--darken-1 font-weight-bold">
                                Todo el equipo
                            </span>
                        </template>
                        <template v-slot:prepend-item>
                            <v-list-item @click="allEmployees=!allEmployees">
                                <v-list-item-action>
                                    <v-checkbox v-model="allEmployees" readonly color="primary"></v-checkbox> 
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>Todo el equipo</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
    <!-- TODO: Añadir restricciones de tiempo (fecha de inicio < fecha fin) -->
    <!-- TODO: Hacer dinámico el primer día de la semana -->
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'VacationsFormView',
    props: {
        vacationId: {
            type: String,
            required: true
        }
    },
    components: {
    },
    data() {
        return {
            hasChanged: false,
            menuStartDate: false,
            menuEndDate: false,
            menuStartTime: false,
            menuEndTime: false,
            vacation: {
                isFullDay: true,
                employees: [],
                startDate: '',
                endDate: '',
            },
            defaultVacation: '',
        }
    },
    computed: {
        ...mapGetters(['employees']),
        allEmployees: {
            get() {
                return this.vacation.employees.length === this.employees.length
            },
            set(value) {
                if (value) {
                    this.vacation.employees = Object.assign([], this.employees)
                } else {
                    this.vacation.employees = []
                }
            }
        },
        serializedVacation() {
            return JSON.stringify(this.vacation)
        },
        start() {
            return moment(this.vacation.startDate)
        },
        end() {
            return moment(this.vacation.endDate)
        },
        startDate: {
            get() {
                return this.start.format('YYYY-MM-DD');
            },
            set(value) {
                const updatedStart = moment(value);
                const oldStart = moment(this.vacation.startDate);

                // Mantenemos la misma hora, pero cambiamos la fecha
                updatedStart.hour(oldStart.hour()).minute(oldStart.minute()).second(oldStart.second());

                this.vacation.startDate = updatedStart.format();
            }
        },
        endDate: {
            get() {
                return this.end.format('YYYY-MM-DD');
            },
            set(value) {
                const updatedEnd = moment(value);
                const oldEnd = moment(this.vacation.endDate);

                // Mantenemos la misma hora, pero cambiamos la fecha
                updatedEnd.hour(oldEnd.hour()).minute(oldEnd.minute()).second(oldEnd.second());

                this.vacation.endDate = updatedEnd.format();
            }
        },
        startTime: {
            get() {
                return this.start.format('HH:mm');
            },
            set(value) {
                const oldStart = this.start;
                const [hours, minutes] = value.split(':');

                // Cambiamos la hora, pero mantenemos la misma fecha
                oldStart.hour(hours).minute(minutes);

                this.vacation.startDate = oldStart.format();
            }
        },
        endTime: {
            get() {
                return this.end.format('HH:mm');
            },
            set(value) 
            {
                const oldEnd = this.end;
                const [hours, minutes] = value.split(':');

                // Cambiamos la hora, pero mantenemos la misma fecha
                oldEnd.hour(hours).minute(minutes);

                this.vacation.endDate = oldEnd.format();
            }
        },
    },

    watch: {
        serializedVacation() {
            this.hasChanged = this.serializedVacation !== this.defaultVacation && this.defaultVacation !== ''
        }
    },
    async created() {
        if (this.employees.length === 0) await this.fetchEmployees()
        if (this.vacationId === 'new') {
            this.vacation.startDate = moment().format()
            this.vacation.endDate = moment().format()
            this.defaultVacation = JSON.stringify(this.vacation)
        }
        else {
            this.vacation = await this.fetchVacation(this.vacationId)
            this.$nextTick(() => {
                this.defaultVacation = JSON.stringify(this.vacation)
            })
        }
    },
    methods: {
        ...mapActions(['fetchEmployees', 'fetchVacation', 'updateVacation', 'createVacation']),
        async save() {
            if (this.vacationId === 'new') {
                await this.createVacation(this.vacation)
            } else {
                await this.updateVacation(this.vacation)
            }
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/business/vacations`)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
