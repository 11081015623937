<template>
    <v-main app>
        <v-container class="d-flex justify-center pa-lg-6">
            <v-card width="950" flat color="transparent">
                <v-row>
                    <v-col cols="0" lg="4">
                        <v-card outlined>
                            <v-card-text>
                                <v-autocomplete
                                v-model="clientId"
                                label="Buscar"
                                :items="clients"
                                item-text="fullName"
                                item-value="id"
                                outlined
                                dense
                                append-icon="mdi-chevron-down"
                                class="rounded-lg"
                                @input="linkToClient(clientId)"
                                ></v-autocomplete>
                                <span class="ma-1 body-2 text-uppercase font-weight-bold">Descargar clientes</span>
                                <v-btn block elevation="0" dark class="mt-2 py-5 rounded-lg" color="teal accent-4" @click="downloadClients">
                                    <v-icon left>mdi-download</v-icon>
                                    CSV
                                </v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="8">
                        <router-view/>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
    </v-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'ClientsLayout',
    components: {
    },
    data() {
        return {
            clientId: null,
            genders: {
                man: 'Hombre',
                women: 'Mujer',
                unknown: 'Desconocido'
            }
        }
    },
    computed: {
        ...mapGetters(['clients'])
    },
    methods: {
        ...mapActions(['fetchClients']),
        linkToClient(clientId) {
            this.$router.push(`/businesses/${localStorage.getItem('businessId')}/clients/details/${clientId}`)
        },
        downloadClients() {
            // Download clients in CSV format and UTF-8 encoding
            const fileHeaders = ['Nombre completo', 'Email', 'Teléfono', 'Fecha de nacimiento', 'Género', 'Dirección', 'Notas']
            const fileData = this.clients.map(client => {
                return [
                    client.fullName,
                    client.email,
                    client.phoneNumber,
                    client.birthday.substring(0, 10),
                    this.genders[client.gender] || 'Desconocido',
                    client.address,
                    '"' + client.notes.map(note => note.content).join('\n') + '"'
                ]
            })
            const bom = '\uFEFF'
            const csv = bom + fileHeaders.join(';') + '\n' + fileData.map(row => row.join(';')).join('\n')
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', `clientes-${new Date().toISOString().split('T')[0]}.csv`)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    },
    created() {
        if (this.clients.length === 0) this.fetchClients()
    },
}
</script>
