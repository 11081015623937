import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        vacations: [],
    },
    getters: {
        vacations: state => state.vacations,
    },
    mutations: {
        setVacations(state, vacations) {
            state.vacations = vacations
        },
    },
    actions: {
        async fetchVacations({ commit }) {
            api.get(`vacations`, { params: {} })
            .then(response => {
                if (response.status === 200) {
                    commit('setVacations', response.data.vacations)
                }
            })
        },
        async fetchVacation({ commit }, id) {
            const response = await api.get(`vacations/${id}`, { params: {} })
            if (response.status === 200) {
                return response.data.vacation
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener la ausencia', color: 'error' })
            }
        },
        async createVacation({ commit }, vacation) {
            const response = await api.post(`vacations`, vacation)
            if (response.status === 201) {
                commit('setSnackbar', { show: true, text: 'Ausencia creada correctamente', color: 'success' })
                return response.data.vacation
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al crear la ausencia', color: 'error' })
            }
        },
        async updateVacation({ commit }, vacation) {
            const response = await api.put(`vacations/${vacation.id}`, vacation)
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Ausencia actualizada correctamente', color: 'success' })
                return response.data.vacation
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar la ausencia', color: 'error' })
            }
        },
        async deleteVacation({ commit }, id) {
            const response = await api.delete(`vacations/${id}`, { params: {} })
            if (response.status === 204) {
                commit('setSnackbar', { show: true, text: 'Ausencia eliminada correctamente', color: 'success' })
                return true
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al eliminar la ausencia', color: 'error' })
                return false
            }
        },
    },
};
