<template>
    <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="scroll-x-reverse-transition"
    >
        <v-card>
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="10" md="6">
                    <v-card class="elevation-0 pb-16">
                        <v-toolbar
                            class="elevation-0"
                            >
                            <v-btn
                                icon
                                @click="back()"
                                large
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="ml-4 my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Política de cookies</v-card-title>
                        <v-divider class="mx-4"></v-divider>
                        <v-card class="elevation-0 mx-4" v-for="item in cookies_policy" :key="'pp_'+item.id">
                            <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3>{{ item.id }}.- {{ item.title }}</h3></v-card-title>
                            <v-divider v-if="item.title" class="mx-4"></v-divider>
                            <v-card-text class="text-break" v-for="text, idx in item.content" :key="'pp_'+item.id+'_'+idx" style="font-size: 18px;" v-html="text">
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'CookiesPolicyComponent',
  props: ['dialog'],
  data () {
    return {
        cookies_policy: [           
            {
                "id": 0,
                "content": [
                    "Nuestro sitio web utiliza cookies para mejorar la experiencia de los usuarios. Al utilizar nuestro sitio web, usted acepta el uso de cookies de acuerdo con esta política."
                ]
            },
            
            {
                "id": 1,
                "title": "Definiciones",
                "content": [
                    "<b>Cookies</b>: Un cookie es un pequeño archivo que se guarda en su ordenador o dispositivo móvil cuando visita un sitio web.",
                    "<b>Cookies de sesión</b>: Las cookies de sesión son temporales y se borran cuando cierra su navegador.",
                    "<b>Cookies persistentes</b>: Las cookies persistentes permanecen en su dispositivo hasta que las borra manualmente o hasta que su navegador las borra según el periodo de duración establecido dentro del archivo de la cookie.",
                    "<b>Cookies de terceros</b>: Las cookies de terceros son las que se establecen por un sitio web distinto al que está visitando."
                ]
            },
            {
                "id": 2,
                "title": "Uso de cookies",
                "content": [
                    "Utilizamos cookies para recolectar información sobre cómo usted usa nuestro sitio web, por ejemplo, qué páginas visita, cuánto tiempo está en ellas, qué hace y cómo ha llegado hasta allí.",
                    "También utilizamos cookies para recordar las elecciones que ha realizado, como su idioma preferido, para poder proporcionarle una experiencia de usuario más personalizada."
                ]
            },
            {
                "id": 3,
                "title": "Cookies de terceros",
                "content": [
                    "Además, utilizamos cookies de terceros para ayudarnos a analizar el uso de nuestro sitio web y mejorar la funcionalidad de nuestro sitio. Por ejemplo, utilizamos Google Analytics, un servicio de análisis web proporcionado por Google, Inc."
                ]
            },
            {
                "id": 4,
                "title": "Control de cookies",
                "content": [
                    "Usted tiene control sobre las cookies y puede restringir o bloquear las cookies de nuestro sitio web en cualquier momento ajustando la configuración de su navegador. Sin embargo, si desactiva las cookies, es posible que nuestro sitio web no funcione correctamente."
                ]
            },
            {
                "id": 5,
                "title": "Cambios a nuestra política de cookies",
                "content": [
                    "Podemos modificar esta política de cookies de vez en cuando. Cuando hagamos cambios, revisaremos la fecha de la última actualización en la parte superior de esta página. Le animamos a revisar esta política de cookies con regularidad para estar informado sobre nuestro uso de cookies."
                ]
            },
            {
                "id": 6,
                "content": ["Al utilizar nuestro sitio web, usted acepta el uso de cookies de acuerdo con esta política."]
            }
        ],
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
