<template>
    <v-dialog
    :value="dialog"
    fullscreen
    hide-overlay
    transition="scroll-x-reverse-transition"
    >
        <v-card>
            <v-row class="d-flex justify-center">
                <v-col cols="12" sm="10" md="6">
                    <v-card class="elevation-0 pb-16">
                        <v-toolbar
                            class="elevation-0"
                            >
                            <v-btn
                                icon
                                @click="back()"
                                large
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-title class="ml-4 my-6 font-weight-black text-break secondary--text" style="font-size: 35px; line-height: 45px;">Política de privacidad</v-card-title>
                        <v-divider class="mx-4"></v-divider>

                        <v-card class="elevation-0 mx-4" v-for="item in privacy_policy" :key="'pp_'+item.id">
                            <v-card-title v-if="item.title" class="mt-4 font-weight-black text-break secondary--text"><h3>{{ item.id }}.- {{ item.title }}</h3></v-card-title>
                            <v-divider v-if="item.title" class="mx-4"></v-divider>
                            <v-card-text class="text-break" v-for="text in item.content" :key="text" style="font-size: 18px;" v-html="text">
                            </v-card-text>
                        </v-card>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'PrivacyPolicyComponent',
  props: ['dialog'],
  data () {
    return {
        privacy_policy: [           
            {
                id: 0,
                content: [
                    'Al acceder a nuestro sitio web, slotspot.es, usted acepta las políticas de privacidad establecidas a continuación. Lea detenidamente estas políticas antes de utilizar nuestros servicios.',
                ]
            },
            {
                id: 1,
                title: 'Información que recogemos',
                content: [
                    'Recopilamos información personal que usted nos proporciona al registrarse y utilizar nuestros servicios. Esto incluye su nombre, dirección de correo electrónico, número de teléfono y detalles de la tarjeta de crédito.',
                    'También recopilamos información sobre cómo utiliza nuestro sitio web y nuestros servicios, como la fecha y hora de su visita, las páginas que visita y la información de su dispositivo y navegador.'
                ]
            },
            {
                id: 2,
                title: 'Cómo utilizamos su información',
                content: [
                    'Utilizamos su información personal para procesar sus reservas, gestionar su cuenta y proporcionarle nuestros servicios.',
                    'También podemos usar su información para mejorar nuestros servicios, personalizar su experiencia en nuestro sitio web y enviarle noticias y actualizaciones sobre nuestros servicios.'
                ]
            },
            {
                id: 3,
                title: 'Cómo compartimos su información',
                content: [
                    'Podemos compartir su información con los negocios para los que ha reservado servicios a través de nuestro sitio web.',
                    'También podemos compartir su información con terceros que nos ayudan a proporcionar nuestros servicios, como proveedores de servicios de pago y empresas de análisis de datos.',
                    'No vendemos ni alquilamos su información personal a terceros para sus propios fines de marketing sin su consentimiento.'
                ]
            },
            {
                id: 4,
                title: 'Cómo protegemos su información',
                content: [
                    'Protegemos su información personal utilizando medidas de seguridad técnicas y organizativas para reducir los riesgos de pérdida, mal uso, acceso no autorizado, divulgación y alteración.',
                    'Aunque tomamos medidas para proteger su información, ningún sitio web, transmisión de datos por Internet, sistema de almacenamiento de información o método de almacenamiento electrónico es completamente seguro.'
                ]
            },
            {
                id: 5,
                title: 'Sus derechos',
                content: [
                    'Usted tiene el derecho de acceder a la información personal que tenemos sobre usted, y de pedir que corrijamos, actualicemos o eliminemos su información.',
                    'También tiene derecho a oponerse a nuestro uso de su información personal, a limitar nuestro uso de su información y a retirar su consentimiento para nuestro uso de su información.'
                ]
            },
            {
                id: 6,
                title: 'Cambios en nuestra política de privacidad',
                content: ['Bidapp VIP S.L., a través de Slotspot.es se reserva el derecho de modificar esta política de privacidad en cualquier momento. Los usuarios serán notificados de las modificaciones y se les solicitará que acepten la nueva política antes de continuar utilizando los servicios de la página web.']
            },
            {
                id: 7,
                title: 'Contacto',
                content: ['Si tiene alguna pregunta sobre esta política de privacidad, puede ponerse en contacto con nosotros a través de info@slotspot.es.']
            },
            {
                id: 8,
                content: ['Al utilizar los servicios de slotspot.es, los usuarios aceptan las políticas de privacidad establecidas en este documento.']
            }
        ],
    }
  },
  methods: {
    back() {
      this.$emit('update:dialog', false);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
