export const formatDatetime = (date, dateFormat = 'yyyy-MM-dd') => {
    if (typeof date === 'string') date = new Date(date)
    if (!date) date = new Date()
    // Format 'yyyy-MM-dd HH:mm'
    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')

    if (dateFormat === 'yyyy-MM-dd HH:mm') {
        return `${year}-${month}-${day} ${hours}:${minutes}`
    } else if (dateFormat === 'yyyy-MM-dd HH:mm:ss') {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    } else if (dateFormat === 'yyyy-MM-dd') {
        return `${year}-${month}-${day}`
    } else if (dateFormat === 'HH:mm') {
        return `${hours}:${minutes}`
    } else {
        return date.toISOString()
    }
}

export const truncateDecimals = (value, decimals = 2) => parseFloat(value.toFixed(decimals))