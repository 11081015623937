import api from "@/services/api";

export default {
    namespaced: false,
    state: {
        appointments: [],
    },
    getters: {
        appointments: state => state.appointments,
    },
    mutations: {
        setAppointments(state, appointments) {
            state.appointments = appointments
        },
    },
    actions: {
        async fetchAppointments({ commit }) {
            const response = await api.get(`appointments`, { params: {} })
            if (response.status === 200) {
                commit('setAppointments', response.data.appointments)
            }
        },
        async fetchAppointment({ commit }, id) {
            const response = await api.get(`appointments/${id}`, { params: {} })
            if (response.status === 200) {
                return response.data.appointment
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al obtener la reserva', color: 'error' })
            }
        },
        async createAppointment({ commit }, appointment) {
            // commit('startLoading', { root: true })
            const response = await api.post(`appointments`, appointment)
            // commit('stopLoading', { root: true })
            if (response.status === 201) {
                commit('setSnackbar', { show: true, text: 'Reserva creada correctamente', color: 'success' })
                return response.data.appointment
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al crear la reserva', color: 'error' })
            }
        },
        async updateAppointment({ commit }, appointment) {
            // commit('startLoading', { root: true })
            const response = await api.put(`appointments/${appointment.id}`, appointment)
            // commit('stopLoading', { root: true })
            if (response.status === 200) {
                commit('setSnackbar', { show: true, text: 'Reserva actualizada correctamente', color: 'success' })
                return response.data.appointment
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al actualizar la reserva', color: 'error' })
            }
        },
        async deleteAppointment({ commit }, id) {
            const response = await api.delete(`appointments/${id}`, { params: {} })
            if (response.status === 204) {
                commit('setSnackbar', { show: true, text: 'Reserva eliminada correctamente', color: 'success' })
                return true
            } else {
                // TODO: Catch error and proceed
                commit('setSnackbar', { show: true, text: 'Error al eliminar la reserva', color: 'error' })
                return false
            }
        },
    },
};
