<!-- TODO: No añadir botón de añadir categoría -->
<template>     
    <div>
        <v-toolbar flat height="80" class="px-lg-4" style="z-index: 1;">
            <h2>
                Servicios
            </h2>
            <template v-slot:extension>
                <!-- <div> -->
                    <v-text-field v-model="filter" outlined dense solo flat label="Buscar" prepend-inner-icon="mdi-magnify">
                    </v-text-field>
                <!-- </div> -->
                <v-btn
                fab
                color="primary"
                class="mt-n5"
                bottom
                right
                absolute
                @click="$router.push('services/new')"
                >
                <v-icon>mdi-plus</v-icon>
                </v-btn>
            </template>
        </v-toolbar>
        <v-container fluid class="pa-lg-8 pt-lg-8 pt-6">
            <v-card v-for="(service, idx) in filteredServices" :key="`service-${service.id}`" outlined class="rounded-0" :class="idx==0 ? 'rounded-t-lg' : idx==services.length-1 ? 'rounded-b-lg' : ''" :style="idx>0 ? 'border-top: 0;' : ''" style="z-index: 0;">
                <v-card-text class="px-lg-5 black--text d-flex flex-row align-center">
                    <v-avatar :size="$vuetify.breakpoint.mdAndDown ? 45: 60" :color="service.color" tile class="rounded-xl mr-3 mr-lg-5 white--text" style="opacity: 0.7">
                        {{ service.initials }}
                    </v-avatar>
                    <v-row>
                        <v-col cols="10" class="d-flex flex-column justify-center">
                            <span class="body-1 font-weight-bold">{{ service.name }}</span>
                            <div class="mt-1">
                                <v-chip label :small="!$vuetify.breakpoint.mdAndDown" :x-small="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown && 'py-3 ml-0 mr-1'" outlined color="grey lighten-2" >
                                <v-icon small left color="grey">mdi-clock</v-icon>
                                <span class="grey--text text--darken-1">
                                    {{ getServiceDuration(service.id).hours }}h 
                                    {{ getServiceDuration(service.id).minutes }}m
                                </span>
                                </v-chip>
                                <v-chip label :small="!$vuetify.breakpoint.mdAndDown" :x-small="$vuetify.breakpoint.mdAndDown" :class="$vuetify.breakpoint.mdAndDown ? 'py-3 ml-0 mr-1' : 'ml-3'"  outlined color="grey lighten-2">
                                    <v-icon dense left color="grey">mdi-cash</v-icon>
                                    <span class="grey--text text--darken-1">{{ formatPrice(service.price) }}</span>
                                </v-chip>
                            </div>
                        </v-col>
                        <v-col cols="2" class="d-flex justify-end align-center">
                            <v-btn color="teal accent-4" class="font-weight-black" text  @click="$router.push(`services/${service.id}`)" :small="$vuetify.breakpoint.mdAndDown">Editar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-container>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'ServicesView',
    components: {
    },
    data() {
        return {
            filter: '',
        }
    },
    computed: {
        ...mapGetters(['services', 'getServiceDuration']),
        filteredServices() {
            return this.services.filter(service => service.name.toLowerCase().includes(this.filter.toLowerCase()))
        },
    },
    created() {
        this.fetchServices();
    },
    methods: {
        ...mapActions(['fetchServices']),
        // TODO: Make this a global function with business parameters
        formatPrice(price) {
            return Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(price);
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
