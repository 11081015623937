<template>
    <v-app id="app-menu">
        <!-- <AppBar @toggleDrawer="toggleDrawer" @showLogin="showLogin"/> -->
        <!-- <NavigationDrawer v-bind:toggleDrawer="drawer"/> -->
        <router-view />
        <!-- <FooterComponent /> -->
        <LoadingLogo />
    </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
// import AppBar from '@/components/public/AppBar.vue'
// import NavigationDrawer from '@/components/public/NavigationDrawer.vue'
// import FooterComponent from '@/components/public/FooterComponent.vue'
import LoadingLogo from '@/components/common/LoadingLogo.vue'

export default {
    name: 'PublicLayout',
    components: { 
        // NavigationDrawer, 
        // AppBar, 
        // FooterComponent, 
        LoadingLogo
    },
    data () {
        return {
            drawer: false,
            loginDialog: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        toggleDrawer () {
            this.drawer = !this.drawer
        },
        showLogin () {
            this.loginDialog = true
        },
    },
}
</script>

<style scoped>

</style>
